import React, { FC, Key, useContext, useEffect, useState } from 'react';
import { WidgetProps } from '../../../models/Widget/WidgetProps';
import { PbsDynamicWidgetValue } from '../../../models/Widget/PbsDynamicWidgetValue';
import { WidgetEvent } from '../../../models/Widget/WidgetEvent';
import ConfigNameContext from '../../../contexts/ConfigNameContext';
import { ModuleRouteInfo, StateModel } from '../../../redux/models/state.model';
import { useSelector } from 'react-redux';
import { getWidgetDynamicData } from '../../../api/resource';
import { TreeNodeViewInfo } from '../../../models/ModuleInfo';
import { WidgetData } from '../../../models/Widget/WidgetData';
import { Tree } from 'antd';
import style from './TreeListView.module.scss';
import { TreeDataType, TreeDynamicData } from '../../../models/Widget/TreeDynamicData';
import ActionTypeContext from '../../../contexts/ActionTypeContext';
import { valueFromConfig } from '../../../utils/ValueFromOptions';
import { DownOutlined } from '@ant-design/icons';
import { ActionTypes, EventTypes } from '../../../models/enums/EventTypes.enum';
import { useAppDispatch } from '../../../redux/store';
import { resetData } from '../../../redux/reducers/ActionsReducer';
import { GetWidgetDynamicDataParams } from '../../../models/GetWidgetDynamicDataParams';
import { EventDataNode } from 'rc-tree/lib/interface';
import { useSetDefaultWidgetValue } from '../../../hooks/useSetDefaultWidgetValue';
import { TableWidgetAction } from '../../../models/Widget/WidgetTableConfig';

interface TreeWidgetData extends TreeNodeViewInfo {
  rawNode: TreeDynamicData;
}

export interface TreeListOptions {
  [key: string]: PbsDynamicWidgetValue;
  actions: PbsDynamicWidgetValue<Array<TableWidgetAction>>;
  events: PbsDynamicWidgetValue<Array<PbsDynamicWidgetValue<WidgetEvent>>>;
  fieldName: PbsDynamicWidgetValue<string>;
  get_data_path: PbsDynamicWidgetValue<string>;
}

type TreeNodeEvent = {
  node: EventDataNode<unknown>;
  expanded: boolean;
  nativeEvent: MouseEvent;
};

function treeDynamicDataToTreeWidgetData(item: TreeDynamicData): TreeWidgetData {
  return {
    rawNode: item,
    title: item.title,
    key: `${item.configName}/${item.id.id}/${item.id.type}`,
    isLeaf: item.id.type !== TreeDataType.ROOT,
    selectable: item.id.type !== TreeDataType.ROOT,
  };
}

const TreeListView: FC<WidgetProps<TreeListOptions>> = (props) => {
  const dispatch = useAppDispatch();

  const [events, fieldName, actions] = valueFromConfig(
    props.config?.options,
    'events',
    'fieldName',
    'actions',
  );
  useSetDefaultWidgetValue(`${props.config.formPath}.${fieldName}`);

  const [widgetTreeData, setWidgetTreeData] = useState<Array<TreeWidgetData>>([]);

  const [loadAction, setLoadAction] = useState<TableWidgetAction | undefined>(undefined);

  const moduleInfo: ModuleRouteInfo = useSelector((state: StateModel) => state.moduleRouteInfo);

  const { moduleKey, type } = moduleInfo;

  const successActions = useSelector((state: StateModel) => state.successActions);

  const configName = useContext(ConfigNameContext);

  const eventHandler = useContext(ActionTypeContext);

  const [expandedKeys, setExpandedKeys] = useState<Array<Key>>([]);

  const [paramsHistory, setParamsHistory] = useState<Array<GetWidgetDynamicDataParams>>([]);

  /** Load initial tree data */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => loadTreeData(), []);

  /** Success actions handling */
  useEffect(() => {
    if (successActions.length) {
      successActions
        .filter((a) => a.targetArea === fieldName || a.targetArea === props.areaName)
        .forEach((action) => {
          switch (action.actionType) {
            case ActionTypes.UPDATE_DATA: {
              loadTreeData();
              break;
            }
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successActions]);

  const onSelect = (selectedKeys: Array<Key>, e?: any) => {
    const event = events[0];
    if (selectedKeys.length > 0) {
      const selectedKey: string = selectedKeys[0].toString();
      const loadConfig: Array<string> = selectedKey.split('/');
      switch (event.eventType) {
        case EventTypes.ON_SELECT:
          eventHandler(event.actions[0], loadConfig);
          break;
      }
    }
  };

  const onExpand = (expandedKeys: Array<Key>, event: any) => {
    const { expanded, node } = event;
    const { key, children } = node;

    if (expanded && children === undefined) {
      onLoadData({ key }).then(
        () => {},
        (e) => {
          console.error(e);
        },
      );
    }

    setExpandedKeys(expandedKeys);
  };

  const onLoadData = ({ key }: { key: string }): Promise<unknown> => {
    const { moduleKey } = moduleInfo;

    const [_, id, type] = key.split('/');

    const fieldName: string = props.config.options.fieldName.value;
    // Если дерево не нашло нужного экшена - грузим по старой логике
    const params = loadAction
      ? {
          id,
          type,
          configName: loadAction.options?.configName,
          fieldName,
        }
      : {
          id,
          type,
          configName,
          fieldName,
        };

    const filteredParams = paramsHistory.filter((item) => item.id !== params.id);
    setParamsHistory([...filteredParams, params]);

    return getWidgetDynamicData<WidgetData<Array<TreeDynamicData>>>(moduleKey, params, null)
      .then((response) => {
        const mappedChildren: Array<TreeWidgetData> = response.data.map(
          treeDynamicDataToTreeWidgetData,
        );
        const newWidgetTreeData = updateNode(widgetTreeData, key, mappedChildren);
        setWidgetTreeData(newWidgetTreeData);
      })
      .catch((err) => {
        const newWidgetTreeData = widgetTreeData.map((node) => ({
          ...node,
          children: node.key === key ? [] : node.children,
        }));
        setWidgetTreeData(newWidgetTreeData);
        console.error(err);
      })
      .finally(() => dispatch(resetData(successActions)));
  };

  function loadTreeData() {
    const loadAction = (actions || []).find(
      (action: TableWidgetAction) => action.actionType === 'GET_TREE_DATA_REQUEST',
    );

    // Если дерево не нашло нужного экшена - грузим по старой логике
    if (!loadAction) {
      setExpandedKeys([]);
      getWidgetDynamicData<WidgetData<Array<TreeDynamicData>>>(
        moduleKey,
        {
          id: 'defaultTreeID',
          configName,
          type,
          fieldName,
        },
        null,
      )
        .then((response) => {
          const newTreeData: Array<TreeWidgetData> = (response.data || []).map(
            treeDynamicDataToTreeWidgetData,
          );
          setWidgetTreeData(newTreeData);
        })
        .catch(() => {
          setWidgetTreeData([]);
        });
      setLoadAction(undefined);
      return;
    }
    setLoadAction(loadAction);

    setExpandedKeys([]);
    const httpParams = {
      id: loadAction.options?.id,
      type: loadAction.options?.type,
      configName: loadAction.options?.configName,
      fieldName,
    };

    getWidgetDynamicData<WidgetData<Array<TreeDynamicData>>>(moduleKey, httpParams, null)
      .then((response) => {
        const newTreeData: Array<TreeWidgetData> = (response.data || []).map(
          treeDynamicDataToTreeWidgetData,
        );
        setWidgetTreeData(newTreeData);
      })
      .catch(() => {
        setWidgetTreeData([]);
      });
  }

  return (
    <div className={style.TreeListComponent}>
      <Tree
        showLine
        switcherIcon={<DownOutlined />}
        rootClassName={style.Tree}
        treeData={widgetTreeData}
        expandedKeys={expandedKeys}
        onSelect={onSelect}
        onExpand={onExpand}
      />
    </div>
  );
};

function updateNode(
  treeData: Array<TreeWidgetData>,
  targetKey: string,
  newChildren: Array<TreeWidgetData>,
): Array<TreeWidgetData> {
  return treeData.map((node: any) => {
    if (node.key === targetKey) {
      return { ...node, children: newChildren };
    }
    if (node.children) {
      const updatedChildren = updateNode(node.children, targetKey, newChildren);
      node = { ...node, children: updatedChildren };
    }
    return node;
  });
}

export default TreeListView;
