import React, { FC, Key, useEffect, useState } from 'react';
import style from './Sidebar.module.scss';
import { TreeNodeViewInfo } from '../../models/ModuleInfo';
import { Tree, TreeDataNode } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { ModuleRouteInfo, StateModel } from '../../redux/models/state.model';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../redux/store';
import { fetchModuleChildren } from '../../redux/reducers/modules-reducer';

const SidebarComponent: FC = () => {
  const dispatch = useAppDispatch();

  const treeData: Array<TreeNodeViewInfo> = useSelector(
    (state: StateModel) => state.modules.modulesTreeData,
  );
  const moduleRouteInfo: ModuleRouteInfo = useSelector(
    (state: StateModel) => state.moduleRouteInfo,
  );
  const authorized: boolean = useSelector((state: StateModel) => state.authInfo.authorized);

  const navigate = useNavigate();

  const location = useLocation();

  const [expandedNodes, setExpandedNodes] = useState<Array<Key>>([]);

  const [selectedKeys, setSelectedKeys] = useState<Array<Key>>([]);

  useEffect(() => {
    const splitLocation = location.pathname.split('/');
    setSelectedKeys([
      `${splitLocation[3]}/${splitLocation[4]}/${splitLocation[5]}/${splitLocation[6]}`,
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { moduleKey } = moduleRouteInfo;

    if (moduleKey && expandedNodes.length === 0) {
      setExpandedNodes([moduleKey]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moduleRouteInfo]);

  const settingsData: Array<TreeDataNode> = [
    {
      title: 'О системе',
      isLeaf: true,
      key: 'about',
    },
  ];

  const onLoadData = ({ key }: { key: string }) => dispatch(fetchModuleChildren(key));

  const onSelect = (selectedKeys: Array<Key>, e: any) => {
    if (selectedKeys.length) {
      setSelectedKeys([...selectedKeys]);
      navigate(`${selectedKeys}`, { relative: 'path' });
    }
  };

  const onExpand = (keys: Array<Key>) => setExpandedNodes(keys);

  return (
    <>
      <div className={style.SidebarComponent}>
        <Tree
          rootClassName={style.Tree}
          treeData={treeData}
          loadData={onLoadData}
          onSelect={onSelect}
          onExpand={onExpand}
          expandedKeys={expandedNodes}
          selectedKeys={selectedKeys}
        />

        {authorized && (
          <Tree
            className={style.Settings}
            treeData={settingsData}
            selectedKeys={selectedKeys}
            onSelect={onSelect}
          />
        )}
      </div>
    </>
  );
};

export default SidebarComponent;
