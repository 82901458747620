import React, { useEffect, useState } from 'react';
import { WidgetData } from '../../models/Widget/WidgetData';
import { Col, Row, Spin } from 'antd';
import style from './AboutPage.module.scss';
import { loadPageAbout } from '../../api/resource';

function AboutPageComponent() {
  const [data, setData] = useState<WidgetData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    loadPageAbout()
      .then((response) => setData(response))
      .catch((err) => console.error('Error fetching data:', err))
      .finally(() => setLoading(false));

    return () => {
      setData(null);
    };
  }, []);

  return (
    <>
      {loading && (
        <div className={style.SpinContainer}>
          <Spin className={style.Spin} tip="loading" size={'large'} />
        </div>
      )}

      {data &&
        data.data.map((value: any, index: number) => (
          <Col span={6} style={{ margin: '10px' }} key={index}>
            <div>
              <h3>{value?.name}</h3>
              {Object.keys(value?.data).map((key, index) => (
                <Row justify="space-between" key={`${key}/${index}`}>
                  <div>{key}</div>
                  <div>{value?.data[key] || '-'}</div>
                </Row>
              ))}
            </div>
          </Col>
        ))}
    </>
  );
}

export default AboutPageComponent;
