import React, { FC, useContext, useEffect, useState } from 'react';
import { PbsDynamicWidgetValue } from '../../../models/Widget/PbsDynamicWidgetValue';
import { WidgetProps } from '../../../models/Widget/WidgetProps';
import { Editor } from '@monaco-editor/react';
import FormikContext from '../../../contexts/FormikContext';
import { valueFromConfig } from '../../../utils/ValueFromOptions';
import { useSetDefaultWidgetValue } from '../../../hooks/useSetDefaultWidgetValue';
import { getFieldByFullPath } from '../helpers/formikHelpers';
import { Box, InputLabel, Typography } from '@mui/material';
import style from './MonacoEditorWidget.module.scss';
import { WidgetTypesEnum } from '../../../models/Widget/WidgetTypes.enum';

export interface MonacoEditorWidgetProps {
  [key: string]: PbsDynamicWidgetValue;
}

const MonacoEditorWidget: FC<WidgetProps<MonacoEditorWidgetProps>> = (props) => {
  const formicFromContext = useContext(FormikContext);
  const [label, isRequired, fieldName] = valueFromConfig(
    props.config?.options,
    'label',
    'isRequired',
    'fieldName',
  );
  useSetDefaultWidgetValue(`${props.config.formPath}.${fieldName}`);
  const [language, setLanguage] = useState<string>('python');

  useEffect(() => {
    switch (props.config.type) {
      case WidgetTypesEnum.CODE_EDITOR_GROOVY: {
        setLanguage('groovy');
        break;
      }
      case WidgetTypesEnum.INPUT_JSON: {
        setLanguage('json');
        break;
      }
    }
  }, [props.config.type]);

  const editorDidMount = (editor: any) => {
    editor.getAction('editor.action.formatDocument')?.run?.();
  };

  return (
    <div className={style.monacoContainer}>
      <Box display="flex" alignItems="center">
        <InputLabel>{label}</InputLabel>
        {isRequired && (
          <Typography variant="inherit" color="error">
            *
          </Typography>
        )}
      </Box>
      {formicFromContext?.formik ? (
        <Editor
          value={getFieldByFullPath(
            formicFromContext!.formik.values || {},
            `${props.config.formPath}.${fieldName}`,
          )}
          onChange={(value) => {
            formicFromContext!.formik.setFieldValue(`${props.config.formPath}.${fieldName}`, value);
          }}
          height={'350px'}
          width={'100%'}
          theme={'light'}
          defaultLanguage={language}
          onMount={editorDidMount}
        />
      ) : (
        <Editor
          height={'350px'}
          width={'100%'}
          theme={'light'}
          defaultLanguage={language}
          onMount={editorDidMount}
        />
      )}
    </div>
  );
};

export default MonacoEditorWidget;
