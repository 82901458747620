import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentFormState: { formComponent: null, formData: null, formKey: null },
  focusedElementId: null,
  availableSelectTables: [],
  availableSelectColumns: [],
  selectedTables: [],
  selectedColumns: [],
};

const sqlConstructorSlice = createSlice({
  name: 'sqlConstructor',
  initialState,
  reducers: {
    updateFocusComponent(state, action) {
      state.focusedElementId = action.payload;
    },
    updateCurrentForm(state, action) {
      state.currentFormState = action.payload;
    },
    updateAvailableSelectTables(state, action) {
      state.availableSelectTables = action.payload;
    },
    updateAvailableSelectColumns(state, action) {
      state.availableSelectColumns = action.payload;
    },
    updateSelectedTables(state, action) {
      state.selectedTables = action.payload;
    },
    updateSelectedColumns(state, action) {
      state.selectedColumns = action.payload;
    },
  },
});

export const {
  updateFocusComponent,
  updateCurrentForm,
  updateAvailableSelectTables,
  updateAvailableSelectColumns,
  updateSelectedTables,
  updateSelectedColumns,
} = sqlConstructorSlice.actions;

const sqlConstructorReduce = sqlConstructorSlice.reducer;

export default sqlConstructorReduce;
