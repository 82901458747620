import React, { FC, useContext, useEffect, useState } from 'react';
import { WidgetProps } from '../../../models/Widget/WidgetProps';
import { PbsDynamicWidgetValue } from '../../../models/Widget/PbsDynamicWidgetValue';
import style from './ViewConstructorWidget.module.scss';
import ConstructorProperties from '../ConstructorProperties/ConstructorProperties';
import { GridView } from './GridView';
import PageDataContext from '../../../contexts/PageDataContext';
import { DynamicPageViewConfig } from '../../../models/Page/DynamicPageViewConfig';
import { useDispatch } from 'react-redux';
import { updateFormField } from '../../../redux/reducers/form-builder-reducer';
import FormikContext from '../../../contexts/FormikContext';
import { valueFromConfig } from '../../../utils/ValueFromOptions';
import { getFieldByFullPath } from '../helpers/formikHelpers';
import { cloneDeep } from 'lodash';
import ConstructorAreasCount from '../../../contexts/ConstructorAreasCount';

export interface ViewConstructorWidgetOptions {
  [key: string]: PbsDynamicWidgetValue;
}

enum Sidebar {
  tree = 'tree',
  widgets = 'widgets',
  properties = 'properties',
  none = 'none',
}

const ViewConstructorWidget: FC<WidgetProps<ViewConstructorWidgetOptions>> = (props) => {
  const [fieldName] = valueFromConfig(
    props.config?.options,
    'fieldName',
    'contextFieldPath',
    'additionalProperties',
    'height',
    'width',
  );

  const [sidebar, setSidebar] = useState<Sidebar>(Sidebar.properties);
  const [areaCount, setAreaCount] = useState<number>(0);

  const formikFromContext = useContext(FormikContext);

  const formikValue = formikFromContext?.formik?.values;

  const [fullPath, setFullPath] = useState<string>(`${props.config.formPath}.${fieldName}`);

  useEffect(() => {
    setFullPath(`${props.config.formPath}.${fieldName}`);
  }, [fieldName, props.config.formPath]);

  const rowSize = getFieldByFullPath(formikValue, `${fullPath}.rowSize`)?.length;
  const columnSize = getFieldByFullPath(formikValue, `${fullPath}.columnSize`)?.length;

  useEffect(() => {
    let gridTemplate = cloneDeep(
      getFieldByFullPath(formikValue, `${fullPath}.gridTemplate`),
    ) as Array<string>;
    const rows = getFieldByFullPath(formikValue, `${fullPath}.rowSize`);
    const columns = getFieldByFullPath(formikValue, `${fullPath}.columnSize`);

    if (!(gridTemplate && rows && columns)) {
      return;
    }

    if (rows.length > gridTemplate.length) {
      gridTemplate.push(Array.from({ length: columns.length }, () => '.').join(' '));
    }

    if (columns.length > gridTemplate[0].split(' ').length) {
      gridTemplate = gridTemplate.map((row) => `${row} .`);
    }

    formikFromContext?.formik?.setFieldValue(`${fullPath}.gridTemplate`, gridTemplate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowSize, columnSize]);

  const formConstructor = useContext(PageDataContext)?.data?.main?.formConstructor;

  const dispatch = useDispatch();

  const [fcData, setFCData] = useState<DynamicPageViewConfig>();

  useEffect(() => {
    if (formConstructor) {
      const data = JSON.parse(formConstructor);
      setFCData(data);
    }
  }, [formConstructor]);

  useEffect(() => {
    if (fcData) {
      dispatch(updateFormField({ fieldName, value: JSON.stringify(fcData) }));
      // оставить только formik
      formikFromContext?.formik.setFieldValue(`${props.config.formPath}.${fieldName}`, fcData);
      // console.log(formikFromContext?.formik)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fcData]);

  const handleClick = (value: Sidebar) => {
    if (value === sidebar) {
      setSidebar(Sidebar.none);
      return;
    }
    setSidebar(value);
  };

  return (
    <div className={style.viewConstructor}>
      <ConstructorAreasCount.Provider value={{ areaCount, setAreaCount }}>
        <div className={style.viewConstructor__navItems}>
          <button onClick={() => handleClick(Sidebar.properties)}>Свойства</button>
          <button onClick={() => handleClick(Sidebar.tree)}>Дерево</button>
          <button onClick={() => handleClick(Sidebar.widgets)}>Виджеты</button>
        </div>

        {sidebar !== Sidebar.none && (
          <div className={style.viewConstructor__tree}>
            {(() => {
              switch (sidebar) {
                case Sidebar.properties:
                  return <ConstructorProperties value={fcData} updateValue={setFCData} />;
                case Sidebar.widgets:
                  return <Widgets />;
                case Sidebar.tree:
                  return <Tree />;
                default:
                  return <></>;
              }
            })()}
          </div>
        )}

        <div className={style.viewConstructor__viewArea}>
          <GridView formPath={fullPath} />
        </div>
      </ConstructorAreasCount.Provider>
    </div>
  );
};

export function Widgets() {
  return (
    <>
      <h1>Виджеты</h1>
    </>
  );
}

export function Tree() {
  return (
    <>
      <h1>Дерево</h1>
    </>
  );
}

export default ViewConstructorWidget;
