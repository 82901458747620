import React, { useEffect } from 'react';
import { useAppDispatch } from '../../redux/store';
import { fetchToken } from '../../redux/reducers/authInfo-reducer';
import { Spin } from 'antd';
import { useNavigate } from 'react-router-dom';

const Callback = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const redirect = () => setTimeout(() => navigate('/app'), 200);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const code = urlParams.get('code') || '';

    sessionStorage.setItem('code', code);

    if (code) {
      dispatch(fetchToken({ code, redirect }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Spin tip="Загрузка" size="large" fullscreen />;
};

export default Callback;
