import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import globalStyles from '../ThemeConfigPage/Theme.module.scss';

interface ModalWrapperProps {
  title: string;
  content: React.ReactNode;
  submitAction: () => void;
}

export const ThemeModalWrapper: React.FC<ModalWrapperProps> = ({
  title,
  content,
  submitAction,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hovered, setHovered] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handlePost = () => {
    submitAction();
    setIsModalOpen(false);
  };

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <>
      <div className={globalStyles.Wrapper}>
        <Button
          className={globalStyles.Button}
          type="primary"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={showModal}
        >
          {hovered ? 'Настроить' : title}
        </Button>
        <Modal
          title="Настройка"
          open={isModalOpen}
          onCancel={handleCancel}
          footer={(_) => (
            <>
              <Button type="primary" danger onClick={handleCancel}>
                Закрыть
              </Button>
              <Button type="primary" onClick={handlePost}>
                Применить
              </Button>
            </>
          )}
        >
          {content}
        </Modal>
      </div>
    </>
  );
};

export default ThemeModalWrapper;
