import React, { useState } from 'react';
import style from '../SqlBlocks.module.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateCurrentForm,
  updateFocusComponent,
} from '../../../../redux/reducers/sqlConstructorReducer';
import { v4 as uuidv4 } from 'uuid';
import { StateModel } from '../../../../redux/models/state.model';

interface Props {
  formState?: any;
  formKey: string;
}

const SqlBlockSelect: React.FC<Props> = ({ formState, formKey }) => {
  const dispatch = useDispatch();
  const componentId = useState(uuidv4())[0];
  const focusedElementId = useSelector(
    (state: StateModel) => state.sqlConstructor.focusedElementId,
  );

  const onRowClick = ($event: any) => {
    dispatch(updateFocusComponent(componentId));
    dispatch(
      updateCurrentForm({
        componentName: 'SelectComponent',
        formState,
        formKey,
      }),
    );
  };

  return (
    <>
      <div
        className={`${style.EditBlock} ${style.Empty} ${componentId === focusedElementId ? style.Focused : ''}`}
        onClick={(e) => onRowClick(e)}
      >
        {formState?.sourceTable && formState?.name
          ? `${formState.sourceTable.alias || formState.sourceTable.name}.${formState.name}`
          : ''}
        {formState?.alias && formState.alias !== 'null' && formState.alias !== ''
          ? ' as ' + formState.alias
          : ''}
      </div>
    </>
  );
};

export default SqlBlockSelect;
