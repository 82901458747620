import { useContext, useEffect, useState } from 'react';
import PageDataContext from '../contexts/PageDataContext';
import FormContext from '../contexts/FormContextType';
import CurrentActionContext from '../contexts/CurrentActionContext';
import { IsLoadAction } from '../utils/isLoadAction';

const useContextByFieldName = (fieldPath: string | undefined) => {
  const pageData = useContext(PageDataContext)?.data;
  const formContext = useContext(FormContext)?.context;
  const currentAction = useContext(CurrentActionContext);
  const [contextByFieldName, setContextByFieldName] = useState<any>(undefined);

  useEffect(() => {
    const contextUrls: Array<string> = fieldPath?.split('.') || [];

    const data = IsLoadAction(currentAction.actionType) ? pageData : formContext;
    setContextByFieldName(contextUrls.reduce((data, path) => data?.[path], data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldPath, currentAction]);

  return contextByFieldName;
};

export default useContextByFieldName;
