import { AvailableFunctionsType, DataFunction } from '../models/Widget/DataFunction';
import { getFieldByFullPath } from '../components/Widgets/helpers/formikHelpers';
import { AnyObject } from '../models/AnyObject';

export const FUNCTIONS_MAP: Record<AvailableFunctionsType, (...args: Array<any>) => any> = {
  GET_BY_FIELD_NAME: (business_object: AnyObject, fieldPath: string) => {
    const value = getFieldByFullPath(business_object, fieldPath);
    return typeof value !== 'undefined' ? value : fieldPath;
  },
  UPPERCASE: (value: any) => {
    if (typeof value === 'string') {
      return value.toUpperCase();
    }
    return value;
  },
  CONCAT: (value: any, ...args: any) => {
    if (args.length > 0) {
      if (value === args[0]) {
        const newArgs = args.slice(1);
        return [value, ...newArgs].join(' ');
      } else {
        return [value, ...args].join(' ');
      }
    }
    return value;
  },
  LOWERCASE: (value: any) => {
    if (typeof value === 'string') {
      return value.toLowerCase();
    }
    return value;
  },
  SLICE: (value: string, ...args: Array<number>) => {
    if (args.length === 1) {
      const index = args[0];
      if (index >= 0 && index < value.length) {
        return value.slice(0, index);
      }
    } else if (args.length === 2) {
      const start = args[0];
      const end = args[1];
      if (start >= 0 && start < value.length && end > start && end <= value.length) {
        return value.slice(start, end);
      }
    }
    return value;
  },
};

export const processValue = (value: any, functions: Array<DataFunction>, logger?: boolean): any => {
  // if (!value || !functions?.length) {
  if (!functions?.length) {
    return value;
  }

  const firstFunction = functions[0];
  if (!firstFunction || !firstFunction.args || firstFunction.args.length === 0) {
    return value;
  }

  // const fieldName = firstFunction.args[0]?.value;
  // const fieldValue = value[fieldName] || fieldName;

  const result = functions.reduce((resultValue: any, functionConfig: DataFunction) => {
    const dataFunction = FUNCTIONS_MAP[functionConfig.type];
    const argsValues = functionConfig.args.map((arg) => {
      if (arg.type === 'FIELD') {
        return value[arg.value] || arg.value;
      }
      return arg.value;
    });

    return dataFunction(resultValue, ...argsValues);
  }, value);
  // }, fieldValue);

  return result;
};
