import { AuthInfo, StateModel } from '../models/state.model';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchRefreshToken, getToken, getUserName } from '../../api/resource';

const initialState: AuthInfo = {
  authorized: false,
  username: null,
  expires_in: null,
  isLoading: false,
  isRefreshed: false,
};

export const fetchToken = createAsyncThunk(
  'auth/token',
  async (arg: { code: string; redirect: () => void }) => {
    const response = await getToken(arg.code);
    return response.data;
  },
  {
    condition: (args, { getState }) => {
      const { authInfo } = getState() as StateModel;
      return !authInfo.isLoading;
    },
  },
);

export const refreshAuthToken = createAsyncThunk(
  'auth/refreshToken',
  async (refreshToken: string) => {
    const response = await fetchRefreshToken(refreshToken);
    return response.data;
  },
  {
    condition: (args, { getState }) => {
      const { authInfo } = getState() as StateModel;
      return !authInfo.isRefreshed;
    },
  },
);

export const fetchUsername = createAsyncThunk(
  'auth/username',
  async (token: string) => {
    const response = await getUserName(token);
    return response.status === 200 ? response.data : null;
  },
  {
    condition: (args, { getState }) => {
      const { authInfo } = getState() as StateModel;
      return args !== null && !authInfo.isLoading;
    },
  },
);

const saveDataAuth = (state: AuthInfo, action: PayloadAction<any>) => {
  const { access_token, expires_in, refresh_token } = action.payload;
  state.authorized = true;
  state.expires_in = expires_in * 1000;

  sessionStorage.clear();
  sessionStorage.setItem('token', access_token);
  sessionStorage.setItem('expires_in', `${expires_in * 1000}`);
  sessionStorage.setItem('refresh_token', refresh_token);
};

const authSlice = createSlice({
  name: 'authInfo',
  initialState,
  reducers: {
    userLoggedIn(state: AuthInfo, action: PayloadAction<string>) {
      state.authorized = true;
    },
    userLoggedOut(state: AuthInfo, action: PayloadAction) {
      state.authorized = false;
      state.username = null;
    },
    tokenRefreshed(state, action: PayloadAction<string>) {
      state.authorized = true;
      state.username = action.payload;
    },
    tokenLoadingSated(state: AuthInfo, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    userName(state: AuthInfo, action: PayloadAction<boolean>) {},
  },
  extraReducers: (builder) => {
    builder.addCase(fetchToken.pending, (state: AuthInfo, action: PayloadAction<any>) => {
      state.isLoading = true;
    });
    builder.addCase(fetchToken.fulfilled, (state: AuthInfo, action: any) => {
      saveDataAuth(state, action);
      state.isLoading = false;
      action.meta.arg.redirect();
    });
    builder.addCase(refreshAuthToken.pending, (state: AuthInfo, action: PayloadAction<any>) => {
      state.isRefreshed = true;
    });
    builder.addCase(refreshAuthToken.fulfilled, (state: AuthInfo, action: PayloadAction<any>) => {
      saveDataAuth(state, action);
      state.isRefreshed = false;
    });
    builder.addCase(fetchUsername.pending, (state: AuthInfo, action: PayloadAction<any>) => {
      state.isLoading = true;
    });
    builder.addCase(fetchUsername.fulfilled, (state: AuthInfo, action: PayloadAction<any>) => {
      state.username = action.payload.sub ? action.payload.sub : 'empty';
      state.isLoading = false;
    });
  },
});

export const { userLoggedIn, userLoggedOut, tokenRefreshed, tokenLoadingSated, userName } =
  authSlice.actions;

const AuthInfoReducer = authSlice.reducer;

export default AuthInfoReducer;
