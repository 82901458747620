import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { StateModel } from '../../../../redux/models/state.model';
import {
  updateCurrentForm,
  updateFocusComponent,
} from '../../../../redux/reducers/sqlConstructorReducer';
import style from '../SqlBlocks.module.css';

interface Props {
  formState: any;
  formKey: string;
}

const SqlBlockGroup: React.FC<Props> = ({ formState, formKey }) => {
  const dispatch = useDispatch();
  const componentId = useState(uuidv4())[0];
  const focusedElementId = useSelector(
    (state: StateModel) => state.sqlConstructor.focusedElementId,
  );

  const onBlockClick = ($event: any) => {
    $event.stopPropagation();
    dispatch(updateFocusComponent(componentId));
    dispatch(updateCurrentForm({ componentName: 'GroupComponent', formState, formKey }));
  };

  return (
    <div
      className={`${style.EditBlock} ${style.Empty} ${componentId === focusedElementId ? style.Focused : ''}`}
      onClick={onBlockClick}
    >
      {formState.columns?.map((state: any, index: number) => (
        <>
          {state.sourceTable.alias && state.alias
            ? `${state.sourceTable.alias}.${state.alias}`
            : state.sourceTable.name && state.alias
              ? `${state.sourceTable.name}.${state.alias}`
              : state.sourceTable.alias && state.name
                ? `${state.sourceTable.alias}.${state.name}`
                : `${state.sourceTable.name}.${state.name}`}
          {index !== formState.length - 1 && ','}
        </>
      ))}
    </div>
  );
};

export default SqlBlockGroup;
