import React, { FC, useContext } from 'react';
import { WidgetProps } from '../../../models/Widget/WidgetProps';
import { PbsDynamicWidgetValue } from '../../../models/Widget/PbsDynamicWidgetValue';
import { InboxOutlined } from '@mui/icons-material';
import { Upload } from 'antd';
import { valueFromConfig } from '../../../utils/ValueFromOptions';
import FormikContext from '../../../contexts/FormikContext';

const { Dragger } = Upload;

export interface UploadFileWidgetOptions {
  [key: string]: PbsDynamicWidgetValue;
  fieldName: PbsDynamicWidgetValue<string>;
}

const UploadFileWidget: FC<WidgetProps<UploadFileWidgetOptions>> = (props) => {
  const [fieldName] = valueFromConfig(props.config?.options, 'fieldName');

  const formicFromContext = useContext(FormikContext);

  const handleBeforeUpload = (file: File) => {
    return false;
  };

  const handleFileChange = (info: any) => {
    if (info.file && info.file instanceof File && formicFromContext?.formik) {
      const reader = new FileReader();
      reader.readAsDataURL(info.file);
      reader.onload = () => {
        formicFromContext!.formik.setFieldValue(
          `${props.config.formPath}.${fieldName}`,
          reader.result,
        );
      };
    }
  };

  return (
    <div style={{ padding: '10px', paddingBottom: '30px' }}>
      <Dragger multiple={false} beforeUpload={handleBeforeUpload} onChange={handleFileChange}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Нажмите для выбора или перенесите файл в эту зону</p>
      </Dragger>
    </div>
  );
};

export default UploadFileWidget;
