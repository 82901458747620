import { createSlice } from '@reduxjs/toolkit';
import { ModalState } from '../models/state.model';

const initialState: ModalState = {
  isOpen: false,
  action: null,
  context: { id: null, configName: '', type: '' },
};

const ModalStateSlice = createSlice({
  name: 'modalState',
  initialState,
  reducers: {
    setModalState(state: ModalState, action) {
      return action.payload;
    },
  },
});

export const { setModalState } = ModalStateSlice.actions;

const ModalStateReducer = ModalStateSlice.reducer;

export default ModalStateReducer;
