import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FormBuilder } from '../models/state.model';
import { AnyObject } from '../../models/AnyObject';
import { cloneDeep } from 'lodash';

const initialState: FormBuilder = {
  requiredFields: [],
};

const FormDataSlice = createSlice({
  name: 'formsBuilder',
  initialState,
  reducers: {
    setFormValue(state, action: PayloadAction<AnyObject>) {
      // TODO Явно плохая логика, подумать и переделать
      const formValue = cloneDeep(action.payload);
      Object.keys(formValue).forEach((key) => {
        state[key] = formValue[key];
      });
    },
    updateFormField(state, action: PayloadAction<{ fieldName: string; value: unknown }>) {
      const { fieldName, value } = action.payload;
      state[fieldName] = value;
    },
    setRequiredFields(state, action) {
      state.requiredFields = [...state.requiredFields, action.payload];
    },
    resetFormField(state) {
      state = initialState;
      return state;
    },
  },
});

export const { setFormValue, updateFormField, resetFormField, setRequiredFields } =
  FormDataSlice.actions;

const FormDataReducer = FormDataSlice.reducer;

export default FormDataReducer;
