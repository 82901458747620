import React, { FC } from 'react';
import { DynamicPageViewConfig } from '../../../models/Page/DynamicPageViewConfig';
import { Input } from 'antd';
import { GridTrack } from './GridTrack';

interface ConstructorPropertiesProps {
  value: DynamicPageViewConfig | undefined;
  updateValue: (value: DynamicPageViewConfig) => void;
}

export type FormConstructor = {
  areaConfig: Array<object>;
  gridTemplate: Array<string>;
  columnSize: string;
  rowSize: string;
  columnGap: string;
  rowGap: string;
};

const ConstructorProperties: FC<ConstructorPropertiesProps> = (props) => {
  const { value, updateValue } = props;

  return (
    <>
      <h2>Свойства</h2>
      <div style={{ border: '1px solid black' }}>
        <h3>Строки</h3>
        <GridTrack config={value} fieldName={'rowSize'} updateField={updateValue} />
      </div>
      <div style={{ border: '1px solid black' }}>
        <h3>Колонки</h3>
        <GridTrack config={value} fieldName={'columnSize'} updateField={updateValue} />
      </div>
      <div style={{ border: '1px solid black' }}>
        <h3>Отступы</h3>
        <h6>Отступ строк</h6>
        <Input value={value?.rowGap}></Input>
        <h6>Отступ колонок</h6>
        <Input value={value?.columnGap}></Input>
      </div>
    </>
  );
};

export default ConstructorProperties;
