import { DynamicPageViewConfig } from '../models/Page/DynamicPageViewConfig';
import { GridConfig } from '../models/Page/GridConfig';

export const pageConfigToGridParams = (pageConfig: DynamicPageViewConfig): GridConfig => {
  const parsedTemplateAreas =
    pageConfig?.gridTemplate?.reduce((acc, cur) => {
      return acc + `'${cur}' `;
    }, '') || '';

  const gridTemplateAreas = `${parsedTemplateAreas}`;
  const gridTemplateColumns = pageConfig?.columnSize || '';
  const gridTemplateRows = pageConfig?.rowSize || '';
  return { gridTemplateAreas, gridTemplateColumns, gridTemplateRows };
};
