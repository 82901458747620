export interface TreeDynamicData {
  configName: string;
  id: {
    id: number;
    type: TreeDataType;
  };
  title: string;
}

export enum TreeDataType {
  ROOT = 'ROOT',
  BUSINESS_OBJECT = 'BUSINESS_OBJECT',
  SELECTION = 'SELECTION',
}
