import React, { useState } from 'react';
import style from '../SqlBlocks.module.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateCurrentForm,
  updateFocusComponent,
} from '../../../../redux/reducers/sqlConstructorReducer';
import { v4 as uuidv4 } from 'uuid';
import { StateModel } from '../../../../redux/models/state.model';

interface Props {
  formState?: any;
  formKey: string;
}

const SqlBlockFrom: React.FC<Props> = ({ formState, formKey }) => {
  const dispatch = useDispatch();
  const componentId = useState(uuidv4())[0];
  const focusedElementId = useSelector(
    (state: StateModel) => state.sqlConstructor.focusedElementId,
  );

  const onBlockClick = ($event: any) => {
    $event.stopPropagation();
    dispatch(updateFocusComponent(componentId));
    dispatch(updateCurrentForm({ componentName: 'FromComponent', formState, formKey }));
  };

  return (
    <>
      {(formState?.jacksonType === 'simpleTable' || !formState) && (
        <div
          className={`${style.EditBlock} ${style.Empty} ${componentId === focusedElementId ? style.Focused : ''}`}
          onClick={onBlockClick}
        >
          <div>
            {formState.name}
            {formState.alias && ' as '.concat(formState.alias)}
          </div>
        </div>
      )}
      {formState?.jacksonType === 'joinTable' && (
        <>
          <div
            className={`${style.EditBlock} ${style.JoinBlock} ${componentId === focusedElementId ? style.Focused : ''}`}
            onClick={onBlockClick}
          >
            <div>
              {formState.sourceTable && formState.sourceTable.name}
              {formState.sourceTable.alias && ' as '.concat(formState.sourceTable.alias)}
            </div>

            <div className={style.InnerBlock}>
              {formState.joinType}
              <SqlBlockFrom
                formState={formState.joinTable}
                formKey={formKey.concat('.joinTable')}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SqlBlockFrom;
