import React, { FC, useContext } from 'react';
import style from './GridArea.module.scss';
import DeleteIcon from '@mui/icons-material/Delete';
import { cloneDeep } from 'lodash';
import FormikContext from '../../../../contexts/FormikContext';

interface GridAreaProps {
  gridArea: string;
  formPath: string;
}

const GridArea: FC<GridAreaProps> = ({ gridArea, formPath }) => {
  const formikFromContext = useContext(FormikContext);

  const deleteArea = () => {
    const areasConfig = cloneDeep(
      formikFromContext?.formik.getFieldProps(`${formPath}.areasConfig`).value,
    ) as Array<{ areaName: string }>;
    const gridTemplate = cloneDeep(
      formikFromContext?.formik.getFieldProps(`${formPath}.gridTemplate`).value,
    ) as Array<string>;

    const newAreasConfig = areasConfig.filter((area) => area.areaName !== gridArea);
    const newGridTemplate = gridTemplate.map((row) => row.replaceAll(gridArea, '.'));

    // console.log(areasConfig);
    // console.log(gridTemplate);
    // console.log('///');
    // console.log(newAreasConfig);
    // console.log(newGridTemplate);

    formikFromContext?.formik?.setFieldValue(`${formPath}.areasConfig`, newAreasConfig);
    formikFromContext?.formik?.setFieldValue(`${formPath}.gridTemplate`, newGridTemplate);
  };

  return (
    <div
      style={{
        gridArea,
      }}
      className={style.dropContainer}
    >
      <div className={style.areaToolbar}>
        <div>Область: {gridArea}</div>

        <div className={style.iconContainer}>
          <DeleteIcon className={style.CloseButton} onClick={deleteArea} />
        </div>
      </div>
    </div>
  );
};

export default GridArea;
