import { createSlice } from '@reduxjs/toolkit';
import set from 'lodash/set';

const initialState = {
  sqlConstructor: {
    columns: [],
    source: {
      jacksonType: 'simpleTable',
      name: null,
      alias: null,
      joinType: null,
      joinTable: {},
      sourceTable: {
        jacksonType: null,
        name: null,
        alias: null,
      },
    },
    condition: {
      jacksonType: 'condition',
      leftSource: null,
      leftParam: null,
      operation: null,
      rightSource: null,
      rightParam: {
        jacksonType: null,
        name: null,
        alias: null,
        sourceTable: null,
        aggregateFunction: null,
        id: null,
        value: null,
      },
      conditionType: null,
      expression: [],
    },
    sort: [],
    groupBy: [],
  },
};

const sqlStateServiceSlice = createSlice({
  name: 'sqlStateService',
  initialState,
  reducers: {
    updateFormValues(state, action: { payload: { values: unknown; formKey: string } }) {
      const { values, formKey } = action.payload;
      const constructorValue = state.sqlConstructor;
      set(constructorValue, formKey, values);
      state.sqlConstructor = { ...constructorValue };
    },
  },
});

export const { updateFormValues } = sqlStateServiceSlice.actions;

const sqlStateServiceReducer = sqlStateServiceSlice.reducer;

export default sqlStateServiceReducer;
