import { createContext, Dispatch, SetStateAction } from 'react';
import { FormInnerGridOptions } from '../components/Widgets/FormInnerGrid/FormInnerGrid';

const FormOptionsConfigContext = createContext<
  | {
      formOptions: FormInnerGridOptions;
      setFormOptions: Dispatch<SetStateAction<FormInnerGridOptions>>;
    }
  | undefined
>(undefined);

export default FormOptionsConfigContext;
