import { StateModel } from '../../redux/models/state.model';
import { ConfigProvider } from 'antd';
import React, { FC } from 'react';
import RouterComponent from '../Router/Router.component';
import { defaultThemeConfig, themeSated } from '../../redux/reducers/theme-reducer';
import { useCookies } from 'react-cookie';
import { isEqual } from 'lodash';
import { ConfigurationThemeConfig } from '../../redux/models/ConfigurationThemeConfig';
import { ThemeConfig } from 'antd/es/config-provider/context';
import { useDispatch, useSelector } from 'react-redux';

const ThemeProvider: FC = () => {
  const [cookies] = useCookies(['theme']);

  const dispatch = useDispatch();

  const theme: ConfigurationThemeConfig = useSelector((state: StateModel) => state.theme);

  if (
    cookies.theme &&
    !isEqual(cookies.theme, defaultThemeConfig) &&
    isEqual(theme, defaultThemeConfig)
  ) {
    dispatch(themeSated(cookies.theme));
  }
  return (
    <ConfigProvider theme={theme as ThemeConfig}>
      <RouterComponent />
    </ConfigProvider>
  );
};

export default ThemeProvider;
