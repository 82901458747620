import { ModuleInfo, TreeNodeViewInfo } from '../models/ModuleInfo';
import { ModuleMenuItem } from '../models/ModuleMenuItem';

export const modulesToViewData = (modules: Array<ModuleInfo>): Array<TreeNodeViewInfo> =>
  modules.map((m) => ({
    title: m.module.moduleDisplayName,
    key: m.module.moduleKey,
    selectable: false,
    children: [],
  }));

export const menuItemToViewData = (
  parentKey: string,
  items: Array<ModuleMenuItem>,
): Array<TreeNodeViewInfo> =>
  items.map((i) => {
    return {
      isLeaf: !i.childActions?.length,
      title: i.displayName,
      key: `${parentKey}/${i.actionConfig.configName}/${i.actionConfig.id}/${i.actionConfig.type}`,
      children: i.childActions?.length ? menuItemToViewData(parentKey, i.childActions) : [],
    };
  });
