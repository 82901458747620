import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { SqlBuilderForms } from '../../../../utils/sqlBuilderForms';
import SqlBlockSelect from './SqlBlockSelect';
import style from './SqlBlockSelect.module.css';
import { updateFormValues } from '../../../../redux/reducers/sqlStateServiceReducer';
import { useAppDispatch } from '../../../../redux/store';

interface Props {
  formState: Array<any>;
  formKey: string;
}

const SqlSelectContainer: React.FC<Props> = ({ formState, formKey }) => {
  const dispatch = useAppDispatch();
  const [columns, setColumns] = useState<Array<any>>([]);

  useEffect(() => {
    setColumns([...formState]);
  }, [formState]);

  const openBlock = () => {
    const newColumn = SqlBuilderForms.buildSelectColumnForm();
    const updatedFormState = [...formState, newColumn];
    dispatch(updateFormValues({ values: updatedFormState, formKey }));
  };

  return (
    <div className={style.ContainerSelect}>
      <Button onClick={openBlock} className={style.addButton}>
        ADD
      </Button>
      {columns.map((column, index) => (
        <SqlBlockSelect
          key={`${formKey}-${index}`}
          formKey={`${formKey}.${index}`}
          formState={column}
        />
      ))}
    </div>
  );
};

export default SqlSelectContainer;
