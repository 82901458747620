import React, { FC } from 'react';
import style from './GridTemplate.module.scss';
import { AreasConfig } from '../../models/Page/AreasConfig';
import WidgetFactory from '../../utils/WidgetFactory';
import { GridConfig } from '../../models/Page/GridConfig';

export interface GridTemplateProps extends GridConfig {
  areasConfig: Array<AreasConfig>;
  isMainArea?: boolean;
  viewType: string; // TODO ENUM
  formPath?: string;
}
interface ParseStyle {
  [key: string]: string;
}
const parseStyle = (config: any): ParseStyle => {
  const justifyContent = config.widgetFlow === 'auto' ? 'center' : config;
  const alignItems = config.verticalFlow === 'auto' ? 'center' : config;
  return { gridArea: config.areaName, justifyContent: justifyContent, alignItems: alignItems };
};

const GridTemplateComponent: FC<GridTemplateProps> = (props) => {
  const { gridTemplateAreas, gridTemplateColumns, gridTemplateRows, areasConfig, formPath } = props;

  return (
    <div
      className={`${style.GridWrapper} ${props.isMainArea ? style.InMain : ''}`}
      style={{ gridTemplateAreas, gridTemplateColumns, gridTemplateRows }}
    >
      {areasConfig.map((config) => (
        <div
          key={config.areaName}
          className={`${style.GridFlex} ${props.isMainArea ? style.FlexInMain : ''} ${config.areaName === 'data_area' ? style.GridCenter : ''}`}
          style={parseStyle(config)}
        >
          {WidgetFactory(config.widgetConfig, config.areaName, formPath)}
        </div>
      ))}
    </div>
  );
};

export default GridTemplateComponent;
