import { ModuleRouteInfo } from '../models/state.model';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: ModuleRouteInfo = {
  moduleKey: '',
  configPath: '',
  id: '',
  type: '',
};

const moduleRouteSlice = createSlice({
  name: 'moduleRouteInfo',
  initialState,
  reducers: {
    modulesUrlChanged(state: ModuleRouteInfo, action: PayloadAction<ModuleRouteInfo>) {
      state = { ...action.payload };
      return state;
    },
  },
});

export const { modulesUrlChanged } = moduleRouteSlice.actions;

const ModuleRouteReducer = moduleRouteSlice.reducer;

export default ModuleRouteReducer;
