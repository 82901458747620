import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { SqlBuilderForms } from '../../../../utils/sqlBuilderForms';
import { updateFormValues } from '../../../../redux/reducers/sqlStateServiceReducer';
import { useAppDispatch } from '../../../../redux/store';
import SqlBlockOrder from './SqlBlockOrder';
import style from './SqlBlockOrder.module.css';

interface Props {
  formState: Array<any>;
  formKey: string;
}

const SqlOrderContainer: React.FC<Props> = ({ formState, formKey }) => {
  const dispatch = useAppDispatch();
  const [order, setOrder] = useState<Array<any>>([]);

  useEffect(() => {
    setOrder([...formState]);
  }, [formState]);

  const openBlock = () => {
    const newOrder = SqlBuilderForms.buildOrderForm();
    const updatedFormState = [...formState, newOrder];
    dispatch(updateFormValues({ values: updatedFormState, formKey }));
  };

  return (
    <div className={style.ContainerSelect}>
      <Button onClick={openBlock} className={style.addButton}>
        ADD
      </Button>
      {order.map((order, index) => (
        <SqlBlockOrder
          key={`${formKey}-${index}`}
          formKey={`${formKey}.${index}`}
          formState={order}
        />
      ))}
    </div>
  );
};

export default SqlOrderContainer;
