import React, { FC } from 'react';
import { WidgetProps } from '../../../models/Widget/WidgetProps';
import { PbsDynamicWidgetValue } from '../../../models/Widget/PbsDynamicWidgetValue';

export interface BlankWidgetOptions {
  [key: string]: PbsDynamicWidgetValue;
}

const BlankWidget: FC<WidgetProps<BlankWidgetOptions>> = (props) => {
  return <div></div>;
};

export default BlankWidget;
