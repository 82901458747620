const responseType = 'code';
const clientId = 'ui_client';
const redirectUri = window.location.origin + '/callback';
const scope = 'openid';
const loginUrl = 'oauth2/authorize';
export const environment = {
  appSelector: 'PBS_LOCAL',
  production: false,
  apiPath: 'http://pbs.ec-t.space',
  auth: '/myAuth',
  address: 'https://auth.pbs.ec-tavrida.ru',
  login: `/${loginUrl}?response_type=${responseType}&client_id=${clientId}&scope=${scope}&redirect_uri=${redirectUri}`,
  logout: `/logout?response_type=${responseType}&client_id=${clientId}&scope=${scope}&redirect_uri=${redirectUri}`,
};
