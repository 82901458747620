import React, { FC, useContext } from 'react';
import { PbsDynamicWidgetValue } from '../../../models/Widget/PbsDynamicWidgetValue';
import { WidgetProps } from '../../../models/Widget/WidgetProps';
import { valueFromConfig } from '../../../utils/ValueFromOptions';
import FormikContext from '../../../contexts/FormikContext';
import { useSetDefaultWidgetValue } from '../../../hooks/useSetDefaultWidgetValue';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { getFieldByFullPath } from '../helpers/formikHelpers';
import style from '../InputWidget/InputStringWidget.module.css';
import { Box, InputLabel, Typography } from '@mui/material';

export interface InputDateWidgetOptions {
  [key: string]: PbsDynamicWidgetValue;
  fieldName: PbsDynamicWidgetValue<string>;
}

const InputDateWidget: FC<WidgetProps<InputDateWidgetOptions>> = (props) => {
  const [label, isRequired, fieldName] = valueFromConfig(
    props.config?.options,
    'label',
    'isRequired',
    'fieldName',
  );

  const formicFromContext = useContext(FormikContext);

  useSetDefaultWidgetValue(`${props.config.formPath}.${fieldName}`);

  const value = getFieldByFullPath(
    formicFromContext!.formik.values || {},
    `${props.config.formPath}.${fieldName}`,
  );

  return (
    <div className={style.inputContainer}>
      <Box display="flex" alignItems="center">
        <InputLabel>{label}</InputLabel>
        {isRequired && (
          <Typography variant="inherit" color="error">
            *
          </Typography>
        )}
      </Box>
      {formicFromContext?.formik ? (
        <DatePicker
          size={'large'}
          id={`${props.config.formPath}.${fieldName}`}
          name={`${props.config.formPath}.${fieldName}`}
          value={value ? dayjs(value) : null}
          onChange={(e: any) => {
            formicFromContext!.formik.setFieldValue(
              `${props.config.formPath}.${fieldName}`,
              e?.toISOString() || null,
            );
          }}
        />
      ) : (
        <DatePicker size={'large'} />
      )}
    </div>
  );
};

export default InputDateWidget;
