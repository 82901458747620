import React, { FC, useContext, useEffect, useState } from 'react';
import FormikContext from '../../../contexts/FormikContext';
import { getFieldByFullPath } from '../helpers/formikHelpers';
import style from './GridView.module.scss';
import GridArrayCellForm, { GridCellPosition } from './GridArrayCellForm/GridArrayCellForm';
import GridArea from './GridArea/GridArea';

interface GridViewProps {
  formPath: string;
}

export const GridView: FC<GridViewProps> = ({ formPath }) => {
  const formikFromContext = useContext(FormikContext);

  const [gridValue, setGridValue] = useState<any>();

  const [activeForm, setActiveForm] = useState<boolean>(false);
  const [startCell, setStartCell] = useState<GridCellPosition>(null);
  const [endCell, setEndCell] = useState<GridCellPosition>(null);

  useEffect(() => {
    setGridValue(getFieldByFullPath(formikFromContext?.formik.values, formPath));
  }, [formPath, formikFromContext?.formik.values]);

  const emptyAreaClick = (rowIndex: number, colIndex: number) => {
    if (!activeForm) {
      setActiveForm(true);
      setStartCell({ rowIndex, colIndex });
    }
    setEndCell({ rowIndex, colIndex });
  };

  const getTemplateAreas = (gridTemplate: Array<string> | undefined) => {
    if (!gridTemplate) {
      return '';
    }

    return gridTemplate.map((row) => `"${row}"`).join(' ');
  };

  return (
    <div style={{ position: 'relative', height: '100%' }}>
      <div
        className={style.gridView}
        style={{
          gridTemplateRows: (gridValue?.rowSize || []).join(' '),
          gridTemplateColumns: (gridValue?.columnSize || []).join(' '),
          gridTemplateAreas: getTemplateAreas(
            formikFromContext?.formik?.getFieldProps(`${formPath}.gridTemplate`)?.value,
          ),
        }}
      >
        {(formikFromContext?.formik?.getFieldProps(`${formPath}.areasConfig`)?.value || []).map(
          (area: { areaName: string }, index: number) => {
            return <GridArea gridArea={area.areaName} formPath={formPath} key={index} />;
          },
        )}
      </div>
      <div
        className={style.gridArrayView}
        style={{
          gridTemplateRows: (gridValue?.rowSize || []).join(' '),
          gridTemplateColumns: (gridValue?.columnSize || []).join(' '),
        }}
      >
        {(gridValue?.rowSize || []).map((row: any, rowIndex: number) => {
          return (gridValue?.columnSize || []).map((column: any, colIndex: number) => {
            return (
              <div
                className={style.gridCell}
                onClick={() => emptyAreaClick(rowIndex + 1, colIndex + 1)}
                key={rowIndex + '/' + colIndex}
              >
                row: {rowIndex} col: {colIndex}
              </div>
            );
          });
        })}

        {activeForm && (
          <GridArrayCellForm
            endCell={endCell}
            formPath={formPath}
            startCell={startCell}
            onClose={() => {
              setStartCell(null);
              setEndCell(null);
              setActiveForm(false);
            }}
          />
        )}
      </div>
    </div>
  );
};
