import React, { useEffect } from 'react';
import { useAppDispatch } from '../../../../redux/store';
import { SqlBuilderForms } from '../../../../utils/sqlBuilderForms';
import { OrderTypes } from '../../Constants/order-types.const';
import { useFormik } from 'formik';
import cleanDeep from '../../../../utils/cleenDeep';
import { updateFormValues } from '../../../../redux/reducers/sqlStateServiceReducer';
import * as yup from 'yup';
import Button from '@mui/material/Button';
import { InputLabel, MenuItem, Select } from '@mui/material';
import { useSelector } from 'react-redux';
import { StateModel } from '../../../../redux/models/state.model';

interface Props {
  formState: any;
  formKey: string;
}

const OrderComponent: React.FC<Props> = ({ formState, formKey }) => {
  const dispatch = useAppDispatch();
  const formValues = SqlBuilderForms.buildOrderForm();

  const orderTypes = OrderTypes;

  const selectedTables = useSelector((state: StateModel) => state.sqlConstructor.selectedColumns);

  const validationSchema = yup.object().shape({});

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const formatForm = cleanDeep(values);

      dispatch(updateFormValues({ values: formatForm, formKey }));

      formik.resetForm();
    },
  });

  useEffect(() => {
    formik.setValues(formState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <InputLabel>Column</InputLabel>
      <Select
        fullWidth
        value={formik.values?.fieldName?.alias || formik.values?.fieldName?.name || ''}
        onChange={(event) => {
          const selectedTable = selectedTables.find(
            (table: any) => table.alias === event.target.value || table.name === event.target.value,
          );
          if (selectedTable) {
            formik.setFieldValue('fieldName.sourceTable', {
              jacksonType: 'simpleTable',
              name: selectedTable.name,
              alias: selectedTable.alias,
            });
            formik.setFieldValue('fieldName', {
              name: selectedTable.name,
              alias: selectedTable.alias,
            });
          }
        }}
        onBlur={formik.handleBlur}
        error={formik.touched.fieldName && Boolean(formik.errors.fieldName)}
      >
        {selectedTables.map((table: any, index: number) => (
          <MenuItem key={index} value={table.alias || table.name}>
            {table.alias ? table.alias : table.name}
          </MenuItem>
        ))}
      </Select>

      <InputLabel>Order by</InputLabel>

      <Select
        fullWidth
        name="direction"
        value={formik.values.direction}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.direction && Boolean(formik.errors.direction)}
      >
        {orderTypes.map((type) => (
          <MenuItem key={type.value} value={type.value}>
            {type.title}
          </MenuItem>
        ))}
      </Select>
      <Button
        color="primary"
        variant="contained"
        fullWidth
        type="submit"
        disabled={formik.isSubmitting || !formik.isValid}
      >
        Submit
      </Button>
    </form>
  );
};

export default OrderComponent;
