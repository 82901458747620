import { transform, isEmpty, isPlainObject } from 'lodash';

type Options = {
  cleanKeys?: Array<string | number | symbol>;
  cleanValues?: Array<string>;
  emptyArrays?: boolean;
  emptyObjects?: boolean;
  emptyStrings?: boolean;
  NaNValues?: boolean;
  nullValues?: boolean;
  undefinedValues?: boolean;
};

function cleanDeep<T extends object>(
  object: T,
  {
    cleanKeys = [],
    cleanValues = [],
    emptyArrays = true,
    emptyObjects = true,
    emptyStrings = true,
    NaNValues = false,
    nullValues = true,
    undefinedValues = true,
  }: Options = {},
): T {
  return transform(object, (result: any, value: any, key: string) => {
    if (cleanKeys.includes(key)) {
      return void 0;
    }

    if (Array.isArray(value) || isPlainObject(value)) {
      value = cleanDeep(value, {
        cleanKeys,
        cleanValues,
        emptyArrays,
        emptyObjects,
        emptyStrings,
        NaNValues,
        nullValues,
        undefinedValues,
      });
    }

    if (cleanValues.includes(value)) {
      return void 0;
    }

    if (
      (emptyArrays && Array.isArray(value) && value.length === 0) ||
      (emptyObjects && isPlainObject(value) && isEmpty(value)) ||
      (emptyStrings && value === '')
    ) {
      return void 0;
    }

    if (NaNValues && Number.isNaN(value)) {
      return void 0;
    }

    if (nullValues && value === null) {
      return void 0;
    }

    if (undefinedValues && value === undefined) {
      return void 0;
    }

    result[key] = value;
  });
}

export default cleanDeep;
