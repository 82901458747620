import React, { FC } from 'react';

export interface EmptyWidgetProps {
  widgetType?: string;
}

const EmptyWidget: FC<EmptyWidgetProps> = ({ widgetType }) => {
  return <>EmptyWidget: {widgetType}</>;
};

export default EmptyWidget;
