import axios from 'axios';
import { useDispatch } from 'react-redux';
import { refreshAuthToken } from './redux/reducers/authInfo-reducer';

axios.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem('token');
    if (config.url === '/oauth2/token' || config.url.startsWith('/oauth2/introspect')) {
      config.headers.Authorization = 'Basic dWlfY2xpZW50OnNlY3JldA==';
    } else if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else config.headers.Authorization = 'Basic dWlfY2xpZW50OnNlY3JldA==';
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

const expiresIn = (Number(sessionStorage.getItem('expires_in')) | 10) * 1000;
const refreshInterval = expiresIn * 0.75;
axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      const errorMessage = `?errorMessage=${encodeURIComponent(JSON.stringify(error.message))}`;
      const errorStatus = `&errorStatus=${encodeURIComponent(JSON.stringify(error.response.status))}`;
      const errorStatusText = `&errorStatusText=${encodeURIComponent(JSON.stringify(error.response.statusText))}`;
      window.location.href = '/error' + errorMessage + errorStatus + errorStatusText;
    }
  },
);

const RefreshAccessToken = () => {
  const refreshToken = sessionStorage.getItem('refresh_token');
  const dispatch = useDispatch();
  dispatch(refreshAuthToken(refreshToken)).catch((err) => {
    console.error('Ошибка обновления токена:', err);
  });
};

export default axios;
