import React, { FC } from 'react';
import type { TreeDataNode } from 'antd';
import { ConfigProvider, Tree } from 'antd';
import globalStyles from '../ThemeConfigPage/Theme.module.scss';
import { TreeThemeConfig } from '../../../redux/models/ConfigurationThemeConfig';

const treeData: Array<TreeDataNode> = [
  {
    title: '0-0',
    key: '0-0',
    children: [
      {
        title: '0-0-0',
        key: '0-0-0',
        children: [
          { title: '0-0-0-0', key: '0-0-0-0' },
          { title: '0-0-0-1', key: '0-0-0-1' },
          { title: '0-0-0-2', key: '0-0-0-2' },
        ],
      },
      {
        title: '0-0-1',
        key: '0-0-1',
        children: [
          { title: '0-0-1-0', key: '0-0-1-0' },
          { title: '0-0-1-1', key: '0-0-1-1' },
          { title: '0-0-1-2', key: '0-0-1-2' },
        ],
      },
      {
        title: '0-0-2',
        key: '0-0-2',
      },
    ],
  },
  {
    title: '0-1',
    key: '0-1',
    children: [
      { title: '0-1-0-0', key: '0-1-0-0' },
      { title: '0-1-0-1', key: '0-1-0-1' },
      { title: '0-1-0-2', key: '0-1-0-2' },
    ],
  },
  {
    title: '0-2',
    key: '0-2',
  },
];

const TreePreviewComponent: FC<{ themeConfig: TreeThemeConfig }> = (props) => {
  const theme = {
    components: {
      Tree: {
        ...props.themeConfig,
      },
    },
  };

  return (
    <ConfigProvider theme={theme}>
      <div className={globalStyles.Container}>
        <Tree
          checkable
          defaultExpandedKeys={['0-0-0', '0-0-1']}
          defaultSelectedKeys={['0-0-0', '0-0-1']}
          defaultCheckedKeys={['0-0-0', '0-0-1']}
          treeData={treeData}
        />
      </div>
    </ConfigProvider>
  );
};

export default TreePreviewComponent;
