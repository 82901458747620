import React, { FC, useEffect } from 'react';
import { WidgetProps } from '../../../models/Widget/WidgetProps';
import { PbsDynamicWidgetValue } from '../../../models/Widget/PbsDynamicWidgetValue';
import SqlConstructor from '../../sql-builder/Containers/SqlConstructor/SqlConstructor';
import { useSelector } from 'react-redux';
import { ModuleRouteInfo, StateModel } from '../../../redux/models/state.model';
import { getSelectableData } from '../../../api/resource';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../redux/store';
import {
  updateAvailableSelectTables,
  updateSelectedColumns,
  updateSelectedTables,
} from '../../../redux/reducers/sqlConstructorReducer';
import { SelectableResponse } from '../SelectWidget/models/response';

export interface SqlConstructorWidgetOptions {
  [key: string]: PbsDynamicWidgetValue;
}

interface TableInfo {
  name?: string;
  alias?: string;
}

const SqlConstructorWidget: FC<WidgetProps<SqlConstructorWidgetOptions>> = (props) => {
  const dispatch = useAppDispatch();
  const globalStateSql = useSelector((state: StateModel) => state.sqlStateService.sqlConstructor);
  const { moduleKey } = useParams() as unknown as ModuleRouteInfo;

  useEffect(() => {
    const nodeName = moduleKey;

    const requestBody = {
      filter_info: [
        {
          paramName: 'context',
          type: 'EQUALS',
          paramValue: null,
        },
      ],
      page_info: {
        pageIndex: 0,
        pageSize: 10,
      },
      search_filter: '',
    };
    getSelectableData(nodeName, 'getTablesNames', requestBody)
      .then((data: SelectableResponse) => {
        dispatch(updateAvailableSelectTables(data.data));
      })
      .catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function extractTableInfo(obj: any, tables: Array<TableInfo> = []): Array<TableInfo> {
    if (obj.jacksonType === 'simpleTable') {
      const tableInfo: TableInfo = {};
      if (obj.alias) {
        tableInfo.alias = obj.alias;
      }
      if (obj.name) {
        tableInfo.name = obj.name;
      }
      if (tableInfo.alias !== undefined || tableInfo.name !== undefined) {
        tables.push(tableInfo);
      }
    } else if (obj.jacksonType === 'joinTable') {
      extractTableInfo(obj.sourceTable, tables);
      extractTableInfo(obj.joinTable, tables);
    }
    return tables;
  }

  function updateSelectedTablesFromObject(obj: any) {
    const tablesInfo: Array<TableInfo> = extractTableInfo(obj);
    const selectedTables = tablesInfo.map((table) => ({
      name: table.name,
      alias: table.alias,
    }));
    dispatch(updateSelectedTables(selectedTables.filter((table) => table.name || table.alias)));
  }

  useEffect(() => {
    updateSelectedTablesFromObject(globalStateSql.source);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalStateSql]);

  useEffect(() => {
    dispatch(updateSelectedColumns(globalStateSql.columns));
    console.log(globalStateSql);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalStateSql]);

  return <SqlConstructor formStateSql={globalStateSql} />;
};

export default SqlConstructorWidget;
