import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DataPageAction } from '../models/Page/DynamicPageViewConfig';
import { ModuleRouteInfo, StateModel } from '../redux/models/state.model';
import { WidgetSuccessAction } from '../models/Widget/WidgetEventAction';
import { ExecutePageActionParams } from '../models/GetWidgetDynamicDataParams';
import { executePageAction } from '../api/resource';
import { ActionTypes } from '../models/enums/EventTypes.enum';
import { useDispatch, useSelector } from 'react-redux';
import { updateNotificationMessage } from '../redux/reducers/notificationMesageReducer';
import { resetFormField } from '../redux/reducers/form-builder-reducer';
import { setModalState } from '../redux/reducers/modal-state-reducer';

export function findValueByKey(obj: any, targetKey: string) {
  if (Object.prototype.hasOwnProperty.call(obj, targetKey)) {
    return obj[targetKey];
  } else {
    return 'have not key in obj';
  }
}

export const useHandleSubmitForm = (action: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const modalState = useSelector((state: StateModel) => state.modalState);

  const param: ModuleRouteInfo = useParams() as unknown as ModuleRouteInfo;

  const executeAction = useCallback(
    (action: any, data?: unknown) => {
      const { moduleKey } = param;

      const modalResultData = action?.options; //this.formController.form.value;
      const filteredA = Object.entries(modalResultData)
        .filter(
          ([key, value]) =>
            value !== null &&
            value !== undefined &&
            value !== '' &&
            key !== 'actionKey' &&
            !(key === 'id' && value === null),
        )
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

      const requestBody = {
        formContext: filteredA,
        main: data,
      };

      const { configName, type, id, actionKey } = action.options;

      const actionId = id || '1'; //|| this.formController.formContext?.id

      const successActions: Array<WidgetSuccessAction> = action?.successActions;

      const params: ExecutePageActionParams = {
        configName: configName, // ? configName : modalResultData.formContext.configName,
        id: actionId, // ? actionId : modalResultData.formContext.id,
        type: type, // ? type : modalResultData.formContext.type,
        actionName: actionKey,
      };
      return executePageAction(moduleKey, params, requestBody)
        .then((result: DataPageAction) => {
          if (result) {
            dispatch(updateNotificationMessage(result.data));
            if (action.actionType !== ActionTypes.SUBMIT_FORM_AND_WAIT) {
              if (modalState?.isOpen) {
                dispatch(setModalState(false));
              } else {
                navigate(-1);
              }
            }
          } else {
            throw new Error('');
          }
          return result;
        })
        .catch((error) => {
          console.error('Ошибка в запросе на executePageAction', error);
          dispatch(updateNotificationMessage(error));
        })
        .finally(() => dispatch(resetFormField()));
    },
    [dispatch, navigate, param, modalState],
  );
  return {
    executeAction,
  };
};
