import React, { useEffect, useState } from 'react';
import { Collapse, CollapseProps, ColorPicker, InputNumber } from 'antd';
import TreePreviewComponent from './TreePreview.component';
import globalStyles from '../ThemeConfigPage/Theme.module.scss';
import {
  ConfigurationThemeConfig,
  TreeThemeConfig,
} from '../../../redux/models/ConfigurationThemeConfig';

interface Props {
  onCustomTreeChange: (customTree: TreeThemeConfig) => void;
  fullTheme: ConfigurationThemeConfig;
}

const ThreeCustomizingComponent: React.FC<Props> = ({ onCustomTreeChange, fullTheme }) => {
  const [treeThemeConfig, setTreeThemeConfig] = useState<TreeThemeConfig>(
    fullTheme.components.Tree,
  );

  useEffect(() => {
    onCustomTreeChange(treeThemeConfig);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treeThemeConfig]);

  const preview: CollapseProps['items'] = [
    {
      key: 1,
      label: 'Предпросмотр',
      children: <TreePreviewComponent themeConfig={treeThemeConfig} />,
    },
  ];

  return (
    <>
      <div>
        <div className={globalStyles.Container}>
          <div className={globalStyles.EditBlock}>
            <span>nodeSelectedBg</span>
            <ColorPicker
              showText
              value={treeThemeConfig.nodeSelectedBg || '#fff'}
              onChangeComplete={(color) =>
                setTreeThemeConfig((prevState) => ({
                  ...prevState,
                  nodeSelectedBg: color.toHexString(),
                }))
              }
            />
          </div>
          <div className={globalStyles.EditBlock}>
            <span>nodeHoverBg</span>
            <ColorPicker
              showText
              value={treeThemeConfig.nodeHoverBg}
              onChangeComplete={(color) =>
                setTreeThemeConfig((prevState) => ({
                  ...prevState,
                  nodeHoverBg: color.toHexString(),
                }))
              }
            />
          </div>
          <div className={globalStyles.EditBlock}>
            <span>titleHeight</span>
            <InputNumber
              min={20}
              max={90}
              value={treeThemeConfig.titleHeight}
              onChange={(value) =>
                setTreeThemeConfig((prevState) => ({ ...prevState, titleHeight: value }))
              }
            />
          </div>
        </div>
        <div className={globalStyles.Container}>
          <Collapse defaultActiveKey={['0']} ghost items={preview} />
        </div>
      </div>
    </>
  );
};

export default ThreeCustomizingComponent;
