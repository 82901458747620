import { TitleValueModel } from '../../../models/title-value.model';

export const TableJoinTypes: Array<TitleValueModel> = [
  {
    title: 'Inner join', //ON - обязательно
    value: 'INNER_JOIN',
  },
  {
    title: 'Full join', //ON - необязательно
    value: 'FULL_JOIN',
  },
  {
    title: 'Cross join', //ON - не испольтзуется
    value: 'CROSS_JOIN',
  },
  {
    title: 'Left join', //ON - необязательно
    value: 'LEFT_JOIN',
  },
  {
    title: 'Right join', //ON - необязательно
    value: 'RIGHT_JOIN',
  },
];
