export function getGridAsArray(grid: Array<string>): Array<Array<string>> {
  return grid.reduce(
    (acc, curr) => {
      acc.push(curr.split(' '));
      return acc;
    },
    [] as Array<Array<string>>,
  );
}

export function saveAreaToGrid(
  rowStart: number,
  rowEnd: number,
  columnStart: number,
  columnEnd: number,
  areaName: string,
  gridTemplate: Array<string>,
): Array<string> {
  const arrayGrid = getGridAsArray(gridTemplate);

  return arrayGrid.reduce((grid, row, rowIndex) => {
    const newRow = row
      .reduce((rowAcc, column, columnIndex) => {
        const newCol =
          rowStart - 1 <= rowIndex &&
          rowIndex <= rowEnd - 2 &&
          columnStart - 1 <= columnIndex &&
          columnIndex <= columnEnd - 2
            ? areaName
            : column === areaName
              ? '.'
              : column;
        rowAcc.push(newCol);
        return rowAcc;
      }, [] as Array<string>)
      .join(' ');
    grid.push(newRow);
    return grid;
  }, []);
}
