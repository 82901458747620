import { createSlice } from '@reduxjs/toolkit';

const initialState: any = null;

const notificationMessageSlice = createSlice({
  name: 'notificationMessage',
  initialState,
  reducers: {
    updateNotificationMessage(state, action) {
      state = action.payload;
      return state;
    },
    resetNotificationMessage() {
      return initialState;
    },
  },
});

export const { updateNotificationMessage, resetNotificationMessage } =
  notificationMessageSlice.actions;

const notificationMessageReducer = notificationMessageSlice.reducer;

export default notificationMessageReducer;
