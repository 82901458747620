import { TitleValueModel } from '../../../models/title-value.model';

export const ConditionOperationTypes: Array<TitleValueModel> = [
  {
    title: '=',
    value: 'EQUAL',
  },
  {
    title: '>',
    value: 'MORE',
  },
  {
    title: '<',
    value: 'LESS',
  },
  {
    title: '<>',
    value: 'NOT_EQUAL',
  },
];
