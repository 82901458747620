import {
  ButtonThemeConfig,
  ConfigurationThemeConfig,
  GlobalTokenThemeConfig,
  TreeThemeConfig,
} from '../models/ConfigurationThemeConfig';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const defaultThemeConfig: ConfigurationThemeConfig = {
  token: {
    colorBgContainer: '#fff',
    colorText: '#000000',
  },
  components: {
    Button: {
      primaryColor: '#998e91',
      dangerColor: '#ffffff',
      defaultHoverBg: '#a200ff',
      defaultBg: '#563029',
      colorPrimary: '#19080f',
      defaultColor: '#ffffff',
      textHoverBg: '#D4D7CE',
      linkHoverBg: '#ffffff',
    },
    Tree: {
      nodeSelectedBg: '#5d6bee',
      nodeHoverBg: '#7d88f3',
      titleHeight: 40,
      directoryNodeSelectedBg: '#111',
    },
  },
};

const modulesSlice = createSlice({
  name: 'theme',
  initialState: defaultThemeConfig,
  reducers: {
    themeSated(state: ConfigurationThemeConfig, action: PayloadAction<ConfigurationThemeConfig>) {
      state = { ...action.payload };
      return state;
    },
    treeThemeSated(state: ConfigurationThemeConfig, action: PayloadAction<TreeThemeConfig>) {
      state.components.Tree = action.payload;
    },
    buttonThemeSated(state: ConfigurationThemeConfig, action: PayloadAction<ButtonThemeConfig>) {
      state.components.Button = action.payload;
    },
    globalThemeSated(
      state: ConfigurationThemeConfig,
      action: PayloadAction<GlobalTokenThemeConfig>,
    ) {
      state.token = action.payload;
    },
  },
});

export const { themeSated, treeThemeSated, buttonThemeSated, globalThemeSated } =
  modulesSlice.actions;

const ThemeReducer = modulesSlice.reducer;

export default ThemeReducer;
