export enum InputWidgetTypesEnum {
  INPUT_STRING = 'INPUT_STRING',
  INPUT_NUMBER = 'INPUT_NUMBER',
  INPUT_BOOLEAN = 'INPUT_BOOLEAN',
  SELECT = 'SELECT',
  SELECT_MULTIPLE = 'SELECT_MULTIPLE',
  TEXTAREA = 'TEXTAREA',
  VIEW_CONSTRUCTOR = 'VIEW_CONSTRUCTOR',
  CODE_EDITOR_GROOVY = 'CODE_EDITOR_GROOVY',
  INPUT_JSON = 'INPUT_JSON',
  UPLOAD_FILE = 'UPLOAD_FILE',
  INPUT_DATE = 'INPUT_DATE',
}

export enum WidgetTypesEnum {
  // Заполнять по мере добавления новых виджетов
  TREE_LIST_VIEW = 'TREE_LIST_VIEW',
  PAGE_VIEW = 'PAGE_VIEW',
  LABEL = 'LABEL',
  BUTTON = 'BUTTON',
  TABLE = 'TABLE',
  TABS = 'TABS',
  FORMS_INNER_GRID = 'FORMS_INNER_GRID',
  BLANK = 'BLANK',
  INPUT_STRING = 'INPUT_STRING',
  INPUT_BOOLEAN = 'INPUT_BOOLEAN',
  INPUT_NUMBER = 'INPUT_NUMBER',
  SELECT = 'SELECT',
  TEXTAREA = 'TEXTAREA',
  UPLOAD_FILE = 'UPLOAD_FILE',
  SQL_CONSTRUCTOR = 'SQL_CONSTRUCTOR',
  VIEW_CONSTRUCTOR = 'VIEW_CONSTRUCTOR',
  CODE_EDITOR_GROOVY = 'CODE_EDITOR_GROOVY',
  INPUT_JSON = 'INPUT_JSON',
  INPUT_DATE = 'INPUT_DATE',
}
