import React, { useEffect, useState } from 'react';
import { ColorPicker } from 'antd';
import globalStyles from '../ThemeConfigPage/Theme.module.scss';
import {
  ConfigurationThemeConfig,
  GlobalTokenThemeConfig,
} from '../../../redux/models/ConfigurationThemeConfig';

interface Props {
  onCustomGlobalChange: (customButton: GlobalTokenThemeConfig) => void;
  fullTheme: ConfigurationThemeConfig;
}

const GlobalCustomizingComponent: React.FC<Props> = ({ onCustomGlobalChange, fullTheme }) => {
  const [globalThemeConfig, setGlobalConfig] = useState<GlobalTokenThemeConfig>(fullTheme.token);

  useEffect(() => {
    onCustomGlobalChange(globalThemeConfig);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalThemeConfig]);

  return (
    <div className={globalStyles.Container}>
      <div className={globalStyles.EditBlock}>
        <span>Основной цвет </span>
        <ColorPicker
          showText
          value={globalThemeConfig.colorBgContainer}
          onChangeComplete={(color) =>
            setGlobalConfig((prevState) => ({
              ...prevState,
              colorBgContainer: color.toHexString(),
            }))
          }
        />
      </div>
      <div className={globalStyles.EditBlock}>
        <span>Цвет текста </span>
        <ColorPicker
          showText
          value={globalThemeConfig.colorText}
          onChangeComplete={(color) =>
            setGlobalConfig((prevState) => ({ ...prevState, colorText: color.toHexString() }))
          }
        />
      </div>
    </div>
  );
};

export default GlobalCustomizingComponent;
