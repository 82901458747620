import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WidgetSuccessAction } from '../../models/Widget/WidgetEventAction';

const initialState: any = [];

const SuccessActionSlice = createSlice({
  name: 'successAction',
  initialState,
  reducers: {
    updateData(state, action: PayloadAction<WidgetSuccessAction>) {
      const actionGroup = [...state, action.payload];
      return actionGroup;
    },
    resetData(state: WidgetSuccessAction, action: PayloadAction<any>) {
      return initialState;
    },
  },
});

export const { updateData, resetData } = SuccessActionSlice.actions;

const ActionsReducer = SuccessActionSlice.reducer;

export default ActionsReducer;
