import React, { FC, useContext, useEffect, useState } from 'react';
import { GridParameterInput } from './GridParameterInput';
import Button from '@mui/material/Button';
import { DynamicPageViewConfig } from '../../../models/Page/DynamicPageViewConfig';
import FormikContext from '../../../contexts/FormikContext';

interface GridTrackProps {
  config: DynamicPageViewConfig | undefined;
  fieldName: keyof Omit<DynamicPageViewConfig, 'areasConfig' | 'gridTemplate'>;
  updateField: (value: DynamicPageViewConfig) => void;
}

export const GridTrack: FC<GridTrackProps> = (props) => {
  const { config, fieldName, updateField } = props;

  const [arrayValues, setArrayValues] = useState<Array<string>>(['1fr']);

  useEffect(() => {
    if (config?.[fieldName]) {
      setArrayValues(config?.[fieldName]?.split(' '));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config?.[fieldName]]);

  // console.log('GridTrack', fieldName)

  const formicFromContext = useContext(FormikContext);

  // const a = getFieldByFullPath(formicFromContext!.formik.values || {}, `${props.config.formPath}.${fieldName}`)

  // console.log('formicFromContext?.formik', formicFromContext?.formik)

  useEffect(() => {
    if (arrayValues) {
      setConfigField(fieldName, arrayValues, JSON.stringify(config));
      //TODO разобраться с fullPath
      formicFromContext?.formik?.setFieldValue(`main.formConstructor.${fieldName}`, arrayValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrayValues]);

  const handleValueChange = (index: number, newValue: string) => {
    const updatedArray = [...arrayValues];
    updatedArray[index] = newValue; // Update the specific index
    setArrayValues(updatedArray); //  Set the updated array
  };

  const handleDeleteValue = (index: number) => {
    const updatedArray = arrayValues.filter((_, i) => i !== index); // Remove the value at the specified index
    setArrayValues(updatedArray); // Update state with the new array
  };

  const setConfigField = (fieldName: string, newValue: Array<string>, context: any) => {
    if (!context) return;
    const newContext = Object.assign({}, JSON.parse(context), { [fieldName]: newValue.join(' ') });
    updateField(newContext);
  };

  const handleAddValue = () => {
    const updatedArray = [...arrayValues, '1fr']; // Add a default value (e.g., '1fr')
    setArrayValues(updatedArray); // Update state with the new array
  };

  return (
    <>
      <div>
        <Button
          onClick={handleAddValue}
          style={{ marginTop: '10px' }}
          variant="contained"
          color="primary"
        >
          +
        </Button>
      </div>

      {arrayValues &&
        arrayValues.map((arrayValue: string, index: number, array) => (
          <GridParameterInput
            value={arrayValue}
            index={index}
            key={index}
            onChange={(newValue: string) => handleValueChange(index, newValue)}
            onDelete={handleDeleteValue}
            array={array}
          />
        ))}
    </>
  );
};
