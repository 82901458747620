import React, { FC, useContext } from 'react';
import { Checkbox } from 'antd';
import { WidgetProps } from '../../../models/Widget/WidgetProps';
import { valueFromConfig } from '../../../utils/ValueFromOptions';
import { PbsDynamicWidgetValue } from '../../../models/Widget/PbsDynamicWidgetValue';
import { AnyObject } from '../../../models/AnyObject';
import FormikContext from '../../../contexts/FormikContext';
import { getFieldByFullPath } from '../helpers/formikHelpers';

export interface CheckboxWidgetOptions extends AnyObject {
  label: PbsDynamicWidgetValue<string>;
  isRequired: PbsDynamicWidgetValue<boolean>;
  fieldName: PbsDynamicWidgetValue<string>;
}

const CheckboxWidget: FC<WidgetProps<CheckboxWidgetOptions>> = (props) => {
  const formicFromContext = useContext(FormikContext);

  const [label, isRequired, fieldName] = valueFromConfig(
    props.config?.options,
    'label',
    'isRequired',
    'fieldName',
  );

  return (
    <>
      {formicFromContext?.formik ? (
        <Checkbox
          onChange={formicFromContext!.formik.handleChange}
          id={`${props.config.formPath}.${fieldName}`}
          name={`${props.config.formPath}.${fieldName}`}
          checked={
            !!getFieldByFullPath(
              formicFromContext!.formik.values || {},
              `${props.config.formPath}.${fieldName}`,
            )
          }
        >
          {label}
        </Checkbox>
      ) : (
        <Checkbox>{label}</Checkbox>
      )}
    </>
  );
};

export default CheckboxWidget;
