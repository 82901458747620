import React, { useEffect, useState } from 'react';
import { Collapse, CollapseProps, ColorPicker } from 'antd';
import globalStyles from '../ThemeConfigPage/Theme.module.scss';
import {
  ButtonThemeConfig,
  ConfigurationThemeConfig,
} from '../../../redux/models/ConfigurationThemeConfig';
import ButtonPreviewComponent from './ButtonPreview.component';

interface Props {
  onCustomButtonChange: (customButton: ButtonThemeConfig) => void;
  fullTheme: ConfigurationThemeConfig;
}

const ButtonCustomizingComponent: React.FC<Props> = ({ onCustomButtonChange, fullTheme }) => {
  const [buttonThemeConfig, setButtonThemeConfig] = useState<ButtonThemeConfig>(
    fullTheme.components.Button,
  );

  useEffect(() => {
    onCustomButtonChange(buttonThemeConfig);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonThemeConfig]);

  const preview: CollapseProps['items'] = [
    {
      key: 1,
      label: 'Предпросмотр',
      children: <ButtonPreviewComponent themeConfig={buttonThemeConfig} />,
    },
  ];
  return (
    <div className={globalStyles.Container}>
      <div>
        <h4>Основная кнопка</h4>
        <div className={globalStyles.EditBlock}>
          <span>Цвет</span>
          <ColorPicker
            showText
            value={buttonThemeConfig.colorPrimary}
            onChangeComplete={(color) =>
              setButtonThemeConfig((prevState) => ({
                ...prevState,
                colorPrimary: color.toHexString(),
              }))
            }
          />
        </div>
        <div className={globalStyles.EditBlock}>
          <span>Цвет текста</span>
          <ColorPicker
            showText
            value={buttonThemeConfig.primaryColor}
            onChangeComplete={(color) =>
              setButtonThemeConfig((prevState) => ({
                ...prevState,
                primaryColor: color.toHexString(),
              }))
            }
          />
        </div>
      </div>

      <div>
        <h4>Обычная кнопка</h4>
        <div className={globalStyles.EditBlock}>
          <span>Цвет</span>
          <ColorPicker
            showText
            value={buttonThemeConfig.defaultBg}
            onChangeComplete={(color) =>
              setButtonThemeConfig((prevState) => ({
                ...prevState,
                defaultBg: color.toHexString(),
              }))
            }
          />
        </div>
        <div className={globalStyles.EditBlock}>
          <span>Цвет текста</span>
          <ColorPicker
            showText
            value={buttonThemeConfig.defaultColor}
            onChangeComplete={(color) =>
              setButtonThemeConfig((prevState) => ({
                ...prevState,
                defaultColor: color.toHexString(),
              }))
            }
          />
        </div>
        <div className={globalStyles.EditBlock}>
          <span>Цвет при наведении</span>
          <ColorPicker
            showText
            value={buttonThemeConfig.defaultHoverBg}
            onChangeComplete={(color) =>
              setButtonThemeConfig((prevState) => ({
                ...prevState,
                defaultHoverBg: color.toHexString(),
              }))
            }
          />
        </div>
      </div>

      <div>
        <h4>Остальные кнопки</h4>
        <div className={globalStyles.EditBlock}>
          <span>Цвет текста красных кнопок</span>
          <ColorPicker
            showText
            value={buttonThemeConfig.dangerColor}
            onChangeComplete={(color) =>
              setButtonThemeConfig((prevState) => ({
                ...prevState,
                dangerColor: color.toHexString(),
              }))
            }
          />
        </div>
        <div className={globalStyles.EditBlock}>
          <span>Фон для текстовой кнопки</span>
          <ColorPicker
            showText
            value={buttonThemeConfig.textHoverBg}
            onChangeComplete={(color) =>
              setButtonThemeConfig((prevState) => ({
                ...prevState,
                textHoverBg: color.toHexString(),
              }))
            }
          />
        </div>
        <div className={globalStyles.EditBlock}>
          <span>Фон для ссылочной кнопки</span>
          <ColorPicker
            showText
            value={buttonThemeConfig.linkHoverBg}
            onChangeComplete={(color) =>
              setButtonThemeConfig((prevState) => ({
                ...prevState,
                linkHoverBg: color.toHexString(),
              }))
            }
          />
        </div>
      </div>

      <div>
        <Collapse defaultActiveKey={['0']} ghost items={preview} />
      </div>
    </div>
  );
};

export default ButtonCustomizingComponent;
