import { createContext, Dispatch, SetStateAction } from 'react';

export type FormContextType = {
  configName: string;
  id: string;
  type: string;
};

export interface FormContextValue {
  context?: FormContextType;
  setContext?: Dispatch<SetStateAction<FormContextType>>;
}

const FormContext = createContext<FormContextValue>({});

export default FormContext;
