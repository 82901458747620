import React, { FC, useContext } from 'react';
import { PbsDynamicWidgetValue } from '../../../models/Widget/PbsDynamicWidgetValue';
import { WidgetProps } from '../../../models/Widget/WidgetProps';
import { Input } from 'antd';
import { getFieldByFullPath } from '../helpers/formikHelpers';
import FormikContext from '../../../contexts/FormikContext';
import { valueFromConfig } from '../../../utils/ValueFromOptions';
import style from '../InputWidget/InputStringWidget.module.css';
import { Box, InputLabel, Typography } from '@mui/material';
import { useSetDefaultWidgetValue } from '../../../hooks/useSetDefaultWidgetValue';

export interface TextareaWidgetOptions {
  [key: string]: PbsDynamicWidgetValue;
}

const TextareaWidget: FC<WidgetProps<TextareaWidgetOptions>> = (props) => {
  const [label, isRequired, fieldName] = valueFromConfig(
    props.config?.options,
    'label',
    'isRequired',
    'fieldName',
  );
  useSetDefaultWidgetValue(`${props.config.formPath}.${fieldName}`);

  const { TextArea } = Input;

  const formicFromContext = useContext(FormikContext);

  return (
    <div className={style.inputContainer}>
      <Box display="flex" alignItems="center">
        <InputLabel>{label}</InputLabel>
        {isRequired && (
          <Typography variant="inherit" color="error">
            *
          </Typography>
        )}
      </Box>
      {formicFromContext?.formik ? (
        <TextArea
          rows={4}
          id={`${props.config.formPath}.${fieldName}`}
          name={`${props.config.formPath}.${fieldName}`}
          value={getFieldByFullPath(
            formicFromContext!.formik.values || {},
            `${props.config.formPath}.${fieldName}`,
          )}
          onChange={formicFromContext!.formik.handleChange}
          onBlur={formicFromContext!.formik.handleBlur}
        />
      ) : (
        <TextArea rows={4} />
      )}
    </div>
  );
};

export default TextareaWidget;
