import React, { FC, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setRequiredFields } from '../../../redux/reducers/form-builder-reducer';
import { WidgetProps } from '../../../models/Widget/WidgetProps';
import { valueFromConfig } from '../../../utils/ValueFromOptions';
import { PbsDynamicWidgetValue } from '../../../models/Widget/PbsDynamicWidgetValue';
import { Box, InputLabel, TextField, Typography } from '@mui/material';
import style from './InputStringWidget.module.css';
import { WidgetTypesEnum } from '../../../models/Widget/WidgetTypes.enum';
import FormikContext from '../../../contexts/FormikContext';
import { getFieldByFullPath } from '../helpers/formikHelpers';
import { useSetDefaultWidgetValue } from '../../../hooks/useSetDefaultWidgetValue';

export interface InputWidgetOptions {
  [key: string]: PbsDynamicWidgetValue;

  label: PbsDynamicWidgetValue<string>;
  isRequired: PbsDynamicWidgetValue<boolean>;
  fieldName: PbsDynamicWidgetValue<string>;
}

const InputStringWidget: FC<WidgetProps<InputWidgetOptions>> = (props) => {
  const dispatch = useDispatch();
  const formicFromContext = useContext(FormikContext);
  const [label, isRequired, fieldName] = valueFromConfig(
    props.config?.options,
    'label',
    'isRequired',
    'fieldName',
  );
  useSetDefaultWidgetValue(`${props.config.formPath}.${fieldName}`);

  useEffect(() => {
    if (isRequired) {
      dispatch(setRequiredFields(fieldName));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.config]);

  return (
    <div className={style.inputContainer}>
      <Box display="flex" alignItems="center">
        <InputLabel>{label}</InputLabel>
        {isRequired && (
          <Typography variant="inherit" color="error">
            *
          </Typography>
        )}
      </Box>
      {formicFromContext?.formik ? (
        <TextField
          size="small"
          style={{ width: 200 }}
          className={style.inputField}
          id={`${props.config.formPath}.${fieldName}`}
          name={`${props.config.formPath}.${fieldName}`}
          value={getFieldByFullPath(
            formicFromContext!.formik.values || {},
            `${props.config.formPath}.${fieldName}`,
          )}
          onChange={formicFromContext!.formik.handleChange}
          onBlur={formicFromContext!.formik.handleBlur}
          error={
            formicFromContext!.formik.touched[fieldName] &&
            Boolean(formicFromContext!.formik.errors[fieldName])
          }
          type={props.config.type === WidgetTypesEnum.INPUT_NUMBER ? 'number' : 'text'}
          // helperText={formik.touched[fieldName] && formik.errors[fieldName]}
        />
      ) : (
        <TextField
          size="small"
          style={{ width: 200 }}
          className={style.inputField}
          type={props.config.type === WidgetTypesEnum.INPUT_NUMBER ? 'number' : 'text'}
        />
      )}
    </div>
  );
};

export default InputStringWidget;
