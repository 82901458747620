import React, { useEffect } from 'react';
import { useAppDispatch } from '../../../../redux/store';
import { SqlBuilderForms } from '../../../../utils/sqlBuilderForms';
import { useFormik } from 'formik';
import { updateFormValues } from '../../../../redux/reducers/sqlStateServiceReducer';
import * as yup from 'yup';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';
import { StateModel } from '../../../../redux/models/state.model';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { InputLabel } from '@mui/material';

interface Props {
  formState: any;
  formKey: string;
}

const GroupComponent: React.FC<Props> = ({ formState, formKey }) => {
  const dispatch = useAppDispatch();
  const formValues = SqlBuilderForms.buildGroupByForm();

  const selectedColumns = useSelector((state: StateModel) => state.sqlConstructor.selectedColumns);

  const validationSchema = yup.object().shape({});

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(updateFormValues({ values: values, formKey }));
      formik.resetForm();
    },
  });

  useEffect(() => {
    formik.setValues(formState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        {selectedColumns.map((column: any, index: number) => (
          <>
            <InputLabel>{index + 1}</InputLabel>
            <Select
              fullWidth
              value={
                formik.values?.columns && formik.values?.columns.length > index
                  ? formik.values.columns[index]?.alias || formik.values.columns[index]?.name || ''
                  : ''
              }
              onChange={(event) => {
                const selectedValue = event.target.value;
                const selectedColumn = selectedColumns.find(
                  (table: any) => table.alias === selectedValue || table.name === selectedValue,
                );
                if (selectedColumn) {
                  formik.setFieldValue(`columns[${index}].name`, selectedColumn.name);
                  formik.setFieldValue(`columns[${index}].alias`, selectedColumn.alias);
                  formik.setFieldValue(`columns[${index}].sourceTable`, {
                    jacksonType: 'simpleTable',
                    name: selectedColumn.sourceTable.name,
                    alias: selectedColumn.sourceTable.alias,
                  });
                } else {
                  formik.setFieldValue(`columns[${index}].sourceTable`, {
                    jacksonType: 'simpleTable',
                    name: event.target.value,
                    alias: '',
                  });
                }
              }}
              onBlur={formik.handleBlur}
            >
              {selectedColumns &&
                selectedColumns.map((table: any, index: number) => (
                  <MenuItem key={index} value={table.alias || table.name}>
                    {table.sourceTable.alias && table.alias
                      ? `${table.sourceTable.alias}.${table.alias}`
                      : table.sourceTable.name && table.alias
                        ? `${table.sourceTable.name}.${table.alias}`
                        : table.sourceTable.alias && table.name
                          ? `${table.sourceTable.alias}.${table.name}`
                          : `${table.sourceTable.name}.${table.name}`}
                  </MenuItem>
                ))}
            </Select>
          </>
        ))}
        <Button color="primary" variant="contained" fullWidth type="submit">
          Submit
        </Button>
      </form>
    </>
  );
};

export default GroupComponent;
