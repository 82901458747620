import { TitleValueModel } from '../../../models/title-value.model';

export const ConditionGroupTypesConst: Array<TitleValueModel> = [
  {
    title: 'AND',
    value: 'AND',
  },
  {
    title: 'OR',
    value: 'OR',
  },
  {
    title: 'NOT',
    value: 'NOT',
  },
];
