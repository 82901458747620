import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { FormControlLabel, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import { SqlBuilderForms } from '../../../../utils/sqlBuilderForms';
import { TableJoinTypes } from '../../Constants/table-join-types.const';
import Checkbox from '@mui/material/Checkbox';
import cleanDeep from '../../../../utils/cleenDeep';
import { useAppDispatch } from '../../../../redux/store';
import { updateFormValues } from '../../../../redux/reducers/sqlStateServiceReducer';
import { useSelector } from 'react-redux';
import { StateModel } from '../../../../redux/models/state.model';

type Props = {
  formState: any;
  formKey: string;
};

const FromComponent: React.FC<Props> = ({ formState, formKey }) => {
  const dispatch = useAppDispatch();

  const formValues = SqlBuilderForms.buildFromForm();
  const availableTables = useSelector(
    (state: StateModel) => state.sqlConstructor.availableSelectTables,
  );

  const tableJoinTypes = TableJoinTypes;

  const validationSchema = yup.object().shape({
    name: yup.string().when(['jacksonType', 'sourceTable'], {
      is: (jacksonType: string, sourceTable: any) =>
        jacksonType === 'simpleTable' && !sourceTable?.name,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
    sourceTable: yup.object().shape({
      name: yup.string().when('jacksonType', {
        is: 'simpleTable',
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable(),
      }),
    }),
  });

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const formatForm = cleanDeep(values);

      dispatch(updateFormValues({ values: formatForm, formKey }));

      formik.resetForm();
    },
  });

  useEffect(() => {
    formik.setValues(formState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState]);

  const handleCheckBoxChange = () => {
    const newValue = formik.values.jacksonType === 'simpleTable' ? 'joinTable' : 'simpleTable';
    formik.setFieldValue('jacksonType', newValue);

    if (formik.values.jacksonType === 'joinTable') {
      formik.setFieldValue('name', formik.values?.sourceTable?.name || '');
      formik.setFieldValue('alias', formik.values?.sourceTable?.alias || '');
      formik.setFieldValue('sourceTable', {});
      formik.setFieldValue('joinTable', {});
      formik.setFieldValue('conditions', {});
      formik.setFieldValue('joinType', '');
    } else {
      formik.setFieldValue('sourceTable', {
        name: formik.values.name || '',
        alias: formik.values.alias || '',
        jacksonType: 'simpleTable',
      });
      formik.setFieldValue('joinTable', {
        name: null,
        alias: null,
        jacksonType: 'simpleTable',
      });
      formik.setFieldValue('name', '');
      formik.setFieldValue('alias', '');
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      {formik.values.jacksonType === 'simpleTable' && (
        <>
          <InputLabel>Name</InputLabel>
          <Select
            fullWidth
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name)}
          >
            {availableTables.map((type: any) => (
              <MenuItem key={type.title} value={type.title}>
                {type.title}
              </MenuItem>
            ))}
          </Select>

          <InputLabel>Alias</InputLabel>
          <TextField
            fullWidth
            name="alias"
            value={formik.values.alias}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.alias && Boolean(formik.errors.alias)}
          />
        </>
      )}

      {formik.values.jacksonType === 'joinTable' && (
        <>
          <InputLabel>Name</InputLabel>
          <Select
            fullWidth
            name="sourceTable.name"
            value={formik.values?.sourceTable?.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.sourceTable && Boolean(formik.errors.sourceTable)}
          >
            {availableTables.map((type: any) => (
              <MenuItem key={type.title} value={type.title}>
                {type.title}
              </MenuItem>
            ))}
          </Select>

          <InputLabel>Alias</InputLabel>
          <TextField
            fullWidth
            name="sourceTable.alias"
            value={formik.values?.sourceTable?.alias}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          <InputLabel>Join Type</InputLabel>
          <Select
            fullWidth
            name="joinType"
            value={formik.values.joinType}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.joinType && Boolean(formik.errors.joinType)}
          >
            {tableJoinTypes.map((type) => (
              <MenuItem key={type.value} value={type.value}>
                {type.title}
              </MenuItem>
            ))}
          </Select>
        </>
      )}

      <FormControlLabel
        control={
          <Checkbox
            checked={formik.values.jacksonType === 'joinTable'}
            onChange={() => handleCheckBoxChange()}
          />
        }
        label="Join Table?"
      />

      <Button
        color="primary"
        variant="contained"
        fullWidth
        type="submit"
        disabled={formik.isSubmitting || !formik.isValid}
      >
        Submit
      </Button>
    </form>
  );
};

export default FromComponent;
