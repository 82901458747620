import React, { FC, useEffect, useRef, useState } from 'react';
import style from './Skeleton.module.scss';
import HeaderComponent from '../HeaderComponent/Header.component';
import { Outlet } from 'react-router-dom';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';
import { fetchModules } from '../../redux/reducers/modules-reducer';
import { StateModel } from '../../redux/models/state.model';
import SidebarComponent from '../Sidebar/Sidebar.component';
import { useAppDispatch } from '../../redux/store';
import WidthCalculateContext from '../../contexts/WidthCalculateContext';

const SkeletonComponent: FC = () => {
  const dispatch = useAppDispatch();

  const [foldStatus, changeFoldStatus] = useState<boolean>(false);

  const loading = useSelector((state: StateModel) => state.modules.isModulesLoading);

  useEffect(() => {
    dispatch(fetchModules());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const parentRef = useRef<HTMLDivElement>(null);
  const [parentWidth, setParentWidth] = useState<number>(0);

  useEffect(() => {
    const measureParentWidth = () => {
      if (parentRef.current && parentRef.current.clientWidth !== 0) {
        setParentWidth(parentRef.current.clientWidth);
      }
    };

    measureParentWidth();

    const handleResize = () => {
      measureParentWidth();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <WidthCalculateContext.Provider value={parentWidth}>
      <div className={style.AppComponent + ' row row-d-column'}>
        <header>
          <HeaderComponent changeFoldStatus={changeFoldStatus} />
        </header>

        <div className="fg1 row row-start" style={{ maxWidth: '100%' }}>
          {loading && <Spin tip="Загрузка" size="large" fullscreen />}
          {!loading && (
            <>
              <div className={`${style.sidebar} ${foldStatus ? style.folded : ''}`}>
                <SidebarComponent />
              </div>

              <div className={style.contentWrapper + '  fg1'}>
                <div ref={parentRef} className={style.MainContentWrapper}>
                  <Outlet />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </WidthCalculateContext.Provider>
  );
};

export default SkeletonComponent;
