import React from 'react';
import { InputLabel } from '@mui/material';
import style from './SqlConstructor.module.css';
import SqlBlockFrom from '../SqlBlockFrom/SqlBlockFrom';
import { useSelector } from 'react-redux';
import SelectComponent from '../../components/SelectComponent/SelectComponent';
import FromComponent from '../../components/FromComponent/FromComponent';
import { StateModel } from '../../../../redux/models/state.model';
import SqlSelectContainer from '../SqlBlockSelect/SqlSelectContainer';
import WhereComponent from '../../components/WhereComponent/WhereComponent';
import SqlBlockWhere from '../SqlBlockWhere/SqlBlockWhere';
import SqlOrderContainer from '../SqlBlockOrderBy/SqlOrder.container';
import OrderComponent from '../../components/OrderComponent/OrderComponent';
import SqlBlockGroup from '../SqlBlockGroup/SqlBlockGroup';
import GroupComponent from '../../components/GroupComponent/GroupComponent';

type ComponentMap = {
  [key: string]: React.FC<any>;
};
interface Props {
  formStateSql: any;
}

const SqlConstructor: React.FC<Props> = ({ formStateSql }) => {
  const currentForm = useSelector((state: StateModel) => state.sqlConstructor.currentFormState);

  const componentMap: ComponentMap = {
    SelectComponent: SelectComponent,
    FromComponent: FromComponent,
    WhereComponent: WhereComponent,
    OrderComponent: OrderComponent,
    GroupComponent: GroupComponent,
  };

  const ComponentToRender = currentForm && componentMap[currentForm.componentName];

  return (
    <div className={style.SqlContainer}>
      <div className={style.ConstructorContainer}>
        <div className={style.ConstructorBlock}>
          <InputLabel className={style.Label}>SELECT</InputLabel>
          <SqlSelectContainer formState={formStateSql.columns} formKey={'columns'} />
        </div>

        <div className={style.ConstructorBlock}>
          <InputLabel className={style.Label}>FROM</InputLabel>
          <SqlBlockFrom formState={formStateSql.source} formKey={'source'} />
        </div>

        <div className={style.ConstructorBlock}>
          <InputLabel className={style.Label}>WHERE</InputLabel>
          <SqlBlockWhere formState={formStateSql.condition} formKey={'condition'} />
        </div>

        <div className={style.ConstructorBlock}>
          <InputLabel className={style.Label}>ORDER BY</InputLabel>
          <SqlOrderContainer formState={formStateSql.sort} formKey={'sort'} />
        </div>

        <div className={style.ConstructorBlock}>
          <InputLabel className={style.Label}>GROUP BY</InputLabel>
          <SqlBlockGroup formState={formStateSql.groupBy} formKey={'groupBy'} />
        </div>
      </div>

      <div className={style.FormContainer}>
        {ComponentToRender && (
          <ComponentToRender formState={currentForm.formState} formKey={currentForm.formKey} />
        )}
      </div>
    </div>
  );
};

export default SqlConstructor;
