import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../redux/store';
import { SqlBuilderForms } from '../../../../utils/sqlBuilderForms';
import * as yup from 'yup';
import { ConditionJacksonTypes } from '../../Constants/condition-jacksonType.const';
import {
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { useFormik } from 'formik';
import cleanDeep from '../../../../utils/cleenDeep';
import { updateFormValues } from '../../../../redux/reducers/sqlStateServiceReducer';
import { ConditionGroupTypesConst } from '../../Constants/condition-group-types.const';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';
import { ModuleRouteInfo, StateModel } from '../../../../redux/models/state.model';
import { getSelectableData } from '../../../../api/resource';
import { useParams } from 'react-router-dom';
import { ConditionOperationTypes } from '../../Constants/condition-operatort-types.const';
import { ConditionParamTypes } from '../../Constants/condtition-param-type.const';

interface Props {
  formState?: any;
  formKey: string;
}

const WhereComponent: React.FC<Props> = ({ formState, formKey }) => {
  const dispatch = useAppDispatch();
  const formValues = SqlBuilderForms.buildExpressionForm();
  const { moduleKey } = useParams() as unknown as ModuleRouteInfo;

  const selectedTables = useSelector((state: StateModel) => state.sqlConstructor.selectedTables);
  const [availableSelectColumnsLeft, setAvailableSelectColumnsLeft] = useState<Array<any>>([]);
  const [availableSelectColumnsRight, setAvailableSelectColumnsRight] = useState<Array<any>>([]);

  const validationSchema = yup.object().shape({});

  const jacksonType = ConditionJacksonTypes;
  const groupType = ConditionGroupTypesConst;
  const operationType = ConditionOperationTypes;
  const paramType = ConditionParamTypes;

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const formatForm = cleanDeep(values);
      dispatch(updateFormValues({ values: formatForm, formKey }));

      formik.resetForm();
    },
  });

  const getColumnsForTable = (table: string) => {
    const requestBody = {
      filter_info: [
        {
          paramName: 'table',
          type: 'EQUALS',
          paramValue: table,
        },
      ],
      page_info: {
        pageIndex: 0,
        pageSize: 10,
      },
      search_filter: '',
    };
    return getSelectableData(moduleKey, 'getTableFields', requestBody)
      .then((data: any) => {
        return data.data;
      })
      .catch(() => []);
  };

  useEffect(() => {
    if (formik.values?.leftSource?.name) {
      getColumnsForTable(formik.values?.leftSource?.name)
        .then((columns) => {
          setAvailableSelectColumnsLeft(columns);
        })
        .catch(() => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values?.leftSource]);

  useEffect(() => {
    if (formik.values?.rightSource?.name) {
      getColumnsForTable(formik.values?.rightSource?.name)
        .then((columns) => {
          setAvailableSelectColumnsRight(columns);
        })
        .catch(() => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values?.rightSource]);

  useEffect(() => {
    formik.setValues(formState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState, formKey]);

  const handleJacksonTypeChange = (event: SelectChangeEvent<string>) => {
    formik.resetForm();
    formik.handleChange(event);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <InputLabel>Jackson Type</InputLabel>
      <Select
        fullWidth
        name="jacksonType"
        value={formik.values.jacksonType}
        onChange={handleJacksonTypeChange}
        onBlur={formik.handleBlur}
        error={formik.touched.jacksonType && Boolean(formik.errors.jacksonType)}
      >
        {jacksonType.map((type) => (
          <MenuItem key={type.value} value={type.value}>
            {type.title}
          </MenuItem>
        ))}
      </Select>

      {formik.values.jacksonType === 'condition' && (
        <div>
          <InputLabel>Left table</InputLabel>
          <Select
            fullWidth
            value={
              formik.values?.leftParam?.sourceTable?.alias ||
              formik.values?.leftParam?.sourceTable?.name ||
              ''
            }
            onChange={(event) => {
              const selectedTable = selectedTables.find(
                (table: any) =>
                  table.alias === event.target.value || table.name === event.target.value,
              );
              if (selectedTable) {
                formik.setFieldValue('leftParam.sourceTable', {
                  jacksonType: 'simpleTable',
                  name: selectedTable.name,
                  alias: selectedTable.alias,
                });
                formik.setFieldValue('leftSource', {
                  jacksonType: 'simpleTable',
                  name: selectedTable.name,
                  alias: selectedTable.alias,
                });
              }
            }}
            onBlur={formik.handleBlur}
            error={formik.touched.leftParam && Boolean(formik.errors.leftParam)}
          >
            {selectedTables.map((table: any, index: number) => (
              <MenuItem key={index} value={table.alias || table.name}>
                {table.alias ? table.alias : table.name}
              </MenuItem>
            ))}
          </Select>

          <InputLabel>Left column</InputLabel>
          <Select
            fullWidth
            name="leftParam.name"
            value={formik.values?.leftParam?.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            {availableSelectColumnsLeft &&
              availableSelectColumnsLeft.map((column: any) => (
                <MenuItem key={column.id.id} value={column.id.id}>
                  {column.title}
                </MenuItem>
              ))}
          </Select>

          <InputLabel>Operation</InputLabel>
          <Select
            fullWidth
            name="operation"
            value={formik.values.operation}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.operation && Boolean(formik.errors.operation)}
          >
            {operationType.map((type) => (
              <MenuItem key={type.value} value={type.value}>
                {type.title}
              </MenuItem>
            ))}
          </Select>

          <ToggleButtonGroup
            fullWidth
            color="primary"
            exclusive
            aria-label="Platform"
            value={formik.values.rightParam?.jacksonType}
            onChange={(event, value) => {
              formik.setFieldValue('rightParam.jacksonType', value);
              if (value === 'column') {
                formik.setFieldValue('rightParam.id', '');
                formik.setFieldValue('rightParam.value', '');
              } else if (value === 'parameter') {
                formik.setFieldValue('rightParam.sourceTable', {});
                formik.setFieldValue('rightParam.name', '');
                formik.setFieldValue('rightSource', {});
              }
            }}
          >
            {paramType.map((type) => (
              <ToggleButton key={type.value} value={type.value}>
                {type.title}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>

          {formik.values.rightParam?.jacksonType === 'column' && (
            <div>
              <InputLabel>Right table</InputLabel>
              <Select
                fullWidth
                value={
                  formik.values?.rightParam?.sourceTable?.alias ||
                  formik.values?.rightParam?.sourceTable?.name ||
                  ''
                }
                onChange={(event) => {
                  const selectedTable = selectedTables.find(
                    (table: any) =>
                      table.alias === event.target.value || table.name === event.target.value,
                  );
                  if (selectedTable) {
                    formik.setFieldValue('rightParam.sourceTable', {
                      jacksonType: 'simpleTable',
                      name: selectedTable.name,
                      alias: selectedTable.alias,
                    });
                    formik.setFieldValue('rightSource', {
                      jacksonType: 'simpleTable',
                      name: selectedTable.name,
                      alias: selectedTable.alias,
                    });
                  }
                }}
                onBlur={formik.handleBlur}
                error={formik.touched.rightParam && Boolean(formik.errors.rightParam)}
              >
                {selectedTables.map((table: any, index: number) => (
                  <MenuItem key={index} value={table.alias || table.name}>
                    {table.alias ? table.alias : table.name}
                  </MenuItem>
                ))}
              </Select>

              <InputLabel>Right column</InputLabel>
              <Select
                fullWidth
                name="rightParam.name"
                value={formik.values?.rightParam?.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                {availableSelectColumnsRight &&
                  availableSelectColumnsRight.map((column: any) => (
                    <MenuItem key={column.id.id} value={column.id.id}>
                      {column.title}
                    </MenuItem>
                  ))}
              </Select>
            </div>
          )}

          {formik.values.rightParam?.jacksonType === 'parameter' && (
            <div>
              <InputLabel>ID</InputLabel>
              <TextField
                fullWidth
                name="rightParam.id"
                value={formik.values.rightParam.id}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />{' '}
              <InputLabel>Value</InputLabel>
              <TextField
                fullWidth
                name="rightParam.value"
                value={formik.values.rightParam.value}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          )}
        </div>
      )}
      {formik.values.jacksonType === 'conditions' && (
        <div>
          <InputLabel>Condition Type</InputLabel>
          <Select
            fullWidth
            name="conditionType"
            value={formik.values.conditionType}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.conditionType && Boolean(formik.errors.conditionType)}
          >
            {groupType.map((type) => (
              <MenuItem key={type.value} value={type.value}>
                {type.title}
              </MenuItem>
            ))}
          </Select>
        </div>
      )}

      <Button
        color="primary"
        variant="contained"
        fullWidth
        type="submit"
        disabled={formik.isSubmitting || !formik.isValid}
      >
        Submit
      </Button>
    </form>
  );
};

export default WhereComponent;
