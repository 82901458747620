import { ActionTypes } from '../enums/EventTypes.enum';
import { FormContextType } from '../../contexts/FormContextType';

export const EmptyAction: WidgetEventAction = {
  actionType: ActionTypes.NO_ACTION,
};

export interface WidgetEventAction {
  actionType: ActionTypes;
  actionData?: Array<any> | any;
  options?: {
    targetArea?: string;
    targetForm?: FormContextType;
    widgetConfig?: {
      type: string;
      options: {
        page_key: {
          value: string;
        };
        page_id: {
          value: string;
        };
      };
      dataType: string;
    };
    configName?: string;
    actionKey?: string;
    type?: string;
    id?: string;
  };
  successActions?: Array<WidgetSuccessAction>;
  httpRequestParams?: any;
}

export interface WidgetSuccessAction {
  actionType: ActionTypes;
  targetArea?: string;
  targetForm?: FormContextType;
  targetUrl: string;
  targetAreas: Array<string>;
}
