import React, { FC } from 'react';
import { ButtonThemeConfig } from '../../../redux/models/ConfigurationThemeConfig';
import { Button, ConfigProvider, Flex } from 'antd';

const ButtonPreviewComponent: FC<{ themeConfig: ButtonThemeConfig }> = (props) => {
  const theme = {
    components: {
      Button: {
        ...props.themeConfig,
      },
    },
  };
  return (
    <ConfigProvider theme={theme}>
      <Flex gap="small" wrap="wrap">
        <Button type="primary">Primary Button</Button>
        <Button type="default">Default Button</Button>
        <Button type="primary" danger>
          Danger Button
        </Button>
        <Button type="dashed">Dashed Button</Button>
        <Button type="text">Text Button</Button>
        <Button type="link">Link Button</Button>
      </Flex>
    </ConfigProvider>
  );
};

export default ButtonPreviewComponent;
