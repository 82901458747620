import { ModuleInfo, TreeNodeViewInfo } from '../../models/ModuleInfo';
import { StateModel, StateModuleInfo } from '../models/state.model';
import { menuItemToViewData, modulesToViewData } from '../../utils/modulesToViewData';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getModuleChildren, getModules } from '../../api/resource';

const initialState: StateModuleInfo = {
  loadedModules: [],
  modulesTreeData: [],
  isModulesLoading: false,
};

export const fetchModules = createAsyncThunk(
  'base/modules/modules',
  async () => {
    const res = await getModules();
    return res.data;
  },
  {
    condition: (args, { getState }) => {
      const { modules } = getState() as StateModel;
      return !modules.isModulesLoading;
    },
  },
);

export const fetchModuleChildren = createAsyncThunk(
  'modules/menuItems',
  async (moduleName: string) => {
    const res = await getModuleChildren(moduleName);
    return res.data;
  },
);

const modulesSlice = createSlice({
  name: 'modules',
  initialState,
  reducers: {
    modulesAdded(state: StateModuleInfo, action: PayloadAction<Array<ModuleInfo>>) {
      const { payload } = action;
      state.loadedModules = [...payload];
      state.modulesTreeData = modulesToViewData(payload);
      state.isModulesLoading = false;
    },
    modulesTreeDataAdded(state: StateModuleInfo, action: PayloadAction<Array<TreeNodeViewInfo>>) {
      const { payload } = action;
      state.modulesTreeData = payload;
    },
    modulesLoadingSated(state: StateModuleInfo, action: PayloadAction<boolean>) {
      state.isModulesLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchModules.pending, (state: StateModuleInfo, action) => {
      state.isModulesLoading = true;
    });
    builder.addCase(fetchModules.fulfilled, (state: StateModuleInfo, action) => {
      state.loadedModules = action.payload;
      state.modulesTreeData = modulesToViewData(action.payload);
      state.isModulesLoading = false;
    });
    builder.addCase(fetchModules.rejected, (state: StateModuleInfo, action) => {
      state.loadedModules = [];
      state.modulesTreeData = [];
      state.isModulesLoading = false;
    });
    builder.addCase(fetchModuleChildren.fulfilled, (state: StateModuleInfo, action) => {
      const parentKey = action.meta.arg;
      state.modulesTreeData = state.modulesTreeData.map((node) => ({
        ...node,
        children:
          node.key === parentKey ? menuItemToViewData(parentKey, action.payload) : node.children,
      }));
    });
    builder.addCase(fetchModuleChildren.rejected, (state: StateModuleInfo, action) => {
      // TODO подумать
      const parentKey = action.meta.arg;
      state.modulesTreeData = state.modulesTreeData.map((node) => ({
        ...node,
        children: node.key === parentKey ? [] : node.children,
      }));
    });
  },
});

export const { modulesAdded, modulesTreeDataAdded, modulesLoadingSated } = modulesSlice.actions;

const ModulesReducer = modulesSlice.reducer;

export default ModulesReducer;
