import React, { FC, useState } from 'react';
import { PbsDynamicWidgetValue } from '../../../models/Widget/PbsDynamicWidgetValue';
import { WidgetProps } from '../../../models/Widget/WidgetProps';
import { valueFromConfig } from '../../../utils/ValueFromOptions';
import { DataFunction } from '../../../models/Widget/DataFunction';
import WidgetFactory from '../../../utils/WidgetFactory';
import style from './TabsWidget.module.scss';
import { Tab, Tabs } from '@mui/material';

export interface TabsWidgetOptions {
  // TODO разобраться с типом
  [key: string]: PbsDynamicWidgetValue;
  dataSource: PbsDynamicWidgetValue<Array<DataFunction>>;
  tabs: PbsDynamicWidgetValue<Array<Tab>>;
}

export interface Tab {
  title: string;
  config: any;
}

const TabsWidget: FC<WidgetProps<TabsWidgetOptions>> = (props) => {
  const [dataSource, tabs, parentFields, relatedFormStateOptions, type, value] = valueFromConfig(
    props.config?.options,
    'dataSource',
    'tabs',
    'relatedFormStateOptions',
    'type',
    'value',
  );
  const [activeTab, setActiveTab] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <div className={style.TabWrapper}>
      <Tabs
        TabIndicatorProps={{ sx: { display: 'none' } }}
        sx={{
          '& .MuiTabs-flexContainer': {
            flexWrap: 'wrap',
          },
        }}
        value={activeTab}
        onChange={handleChange}
      >
        {tabs.map((tab: Tab, index: number) => (
          <Tab key={index} label={tab.title} />
        ))}
      </Tabs>
      <div key={activeTab}>
        {WidgetFactory(tabs[activeTab].config.widgetConfig, tabs[activeTab].title)}
      </div>
    </div>
  );
};

export default TabsWidget;
