import { useEffect, useState } from 'react';
import { clearArrayFields, createFormFields } from '../components/Widgets/helpers/formikHelpers';
import { FormikProps, useFormik } from 'formik';
import { FormInnerGridOptions } from '../components/Widgets/FormInnerGrid/FormInnerGrid';
import { AnyObject } from '../models/AnyObject';

const useConfigureFormik = (config: FormInnerGridOptions) => {
  const [formikConfig, setFormikConfig] = useState<AnyObject>(createFormFields(config));
  const [formikEmptyConfig, setFormikEmptyConfig] = useState<AnyObject>(
    clearArrayFields(formikConfig),
  );

  useEffect(() => {
    setFormikConfig(createFormFields(config));
  }, [config]);

  useEffect(() => {
    setFormikEmptyConfig(clearArrayFields(formikConfig));
  }, [formikConfig]);

  const formik: FormikProps<any> = useFormik({
    initialValues: formikEmptyConfig,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  return {
    formik,
    formikEmptyConfig,
    formikConfig,
  };
};

export default useConfigureFormik;
