import React, { FC, useContext, useEffect, useState } from 'react';
import { PbsDynamicWidgetValue } from '../../../models/Widget/PbsDynamicWidgetValue';
import { WidgetProps } from '../../../models/Widget/WidgetProps';
import { WidgetEvent } from '../../../models/Widget/WidgetEvent';
import { ButtonRelatedData } from '../../../models/Widget/RelatedDataWidget';
import { valueFromConfig } from '../../../utils/ValueFromOptions';
import { ActionTypes, EventTypes } from '../../../models/enums/EventTypes.enum';
import { WidgetEventAction } from '../../../models/Widget/WidgetEventAction';
import ActionTypeContext from '../../../contexts/ActionTypeContext';
import { useSelector } from 'react-redux';
import { setModalState } from '../../../redux/reducers/modal-state-reducer';
import { useAppDispatch } from '../../../redux/store';
import { StateModel } from '../../../redux/models/state.model';
import { resetFormField } from '../../../redux/reducers/form-builder-reducer';
import Button from '@mui/material/Button';
import style from './ButtonWidget.module.css';
import { processValue } from '../../../utils/ProcessFunction';
import cleanDeep from '../../../utils/cleenDeep';
import { cloneDeep } from 'lodash';

export interface ButtonWidgetOptions {
  events: PbsDynamicWidgetValue<Array<WidgetEvent>>;
  relatedDataWidget: PbsDynamicWidgetValue<ButtonRelatedData>;
  label: PbsDynamicWidgetValue<string>;
  color: PbsDynamicWidgetValue<string>;
  height: PbsDynamicWidgetValue<string>;

  [key: string]: PbsDynamicWidgetValue;
}

const ButtonWidget: FC<WidgetProps<ButtonWidgetOptions>> = (props) => {
  const dispatch = useAppDispatch();

  const actionHandler = useContext(ActionTypeContext);

  const [events, relatedDataWidget, label, color, height, width, dataSource] = valueFromConfig(
    props.config?.options,
    'events',
    'relatedDataWidget',
    'label',
    'color',
    'height',
    'width',
    'dataSource',
  );

  const [buttonLabel, setButtonLabel] = useState<string>(label);

  useEffect(() => {
    const firstArg = dataSource?.[0]?.args?.[0]?.value;
    if (firstArg) {
      const newLabel = processValue(firstArg, dataSource);
      if (newLabel) {
        setButtonLabel(newLabel);
      }
    }
  }, [dataSource]);

  const [disabled, setDisabled] = useState<boolean>(false);

  const formsBuilder = useSelector((state: StateModel) => state.formsBuilder);
  const requiredFields: boolean = formsBuilder.requiredFields.every((field) => formsBuilder[field]);

  function buttonClicked() {
    const action: WidgetEventAction = events.find(
      (event: WidgetEvent) => event.eventType === EventTypes.ON_CLICK,
    )?.actions[0];

    switch (action.actionType) {
      case ActionTypes.CANCEL_PAGE:
      case ActionTypes.CANCEL_FORM:
        actionHandler(action);
        break;
      case ActionTypes.SUBMIT_FORM_PAGE:
      case ActionTypes.SUBMIT_FORM:
      case ActionTypes.SUBMIT_FORM_AND_WAIT: {
        const { requiredFields, ...formDataWithoutRequiredFields } = formsBuilder;
        // TODO Явно плохая логика (.main), подумать и переделать
        const actionData = cleanDeep(cloneDeep(formDataWithoutRequiredFields.main));
        actionHandler(action, actionData);
        dispatch(resetFormField());
        break;
      }
      case ActionTypes.CHANGE_FORM_STATE: {
        break;
      }
      case ActionTypes.DISPLAY_FORM:
        dispatch(setModalState({ isOpen: true, context: action?.options, action }));
        break;
      case ActionTypes.LOAD_DATA_AND_DISPLAY_FORM:
        dispatch(setModalState({ isOpen: true, context: action?.options, action }));
        break;
      case ActionTypes.DISPLAY_FORM_PAGE:
      case ActionTypes.LOAD_DATA_AND_DISPLAY_FORM_PAGE:
        actionHandler(action);
        break;
      case ActionTypes.DOWNLOAD_FILE:
      default:
        break;
    }
  }

  const submitActionTypes = [
    String(ActionTypes.SUBMIT_FORM),
    String(ActionTypes.SUBMIT_FORM_AND_WAIT),
    String(ActionTypes.SUBMIT_FORM_PAGE),
  ];

  useEffect(() => {
    const event = events.find((event: WidgetEvent) => event.eventType === EventTypes.ON_CLICK);
    const action = event?.actions?.find((a: WidgetEventAction) =>
      submitActionTypes.includes(a.actionType),
    );

    if (action && !requiredFields) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requiredFields, formsBuilder]);

  return (
    <>
      <Button
        className={style.button}
        variant="contained"
        onClick={buttonClicked}
        // TODO человеческая валидация и disabled
        disabled={false}
        size="medium"
        disableElevation
        style={{ height, width, display: 'block' }}
      >
        {buttonLabel}
      </Button>
    </>
  );
};

export default ButtonWidget;
