export const SqlBuilderForms = {
  buildSelectForm: () => {
    return {
      jacksonType: 'selection',
      columns: [],
      source: {},
      condition: {},
      sort: [],
      groupBy: [],
      join: [],
    };
  },

  buildGroupByForm: () => {
    return {
      columns: [
        {
          name: '',
          alias: '',
          sourceTable: {
            jacksonType: '',
            name: '',
            alias: '',
          },
        },
      ],
      having: '',
    };
  },

  buildFromForm: () => {
    return {
      jacksonType: 'simpleTable',
      name: '',
      alias: '',
      sourceTable: {
        jacksonType: '',
        name: '',
        alias: '',
      },
      joinType: {
        jacksonType: '',
        name: '',
        alias: '',
      },
      joinTable: {
        jacksonType: '',
        name: '',
        alias: '',
      },
      // conditions: SqlBuilderForms.buildJoinConditionForm(),
    };
  },

  buildSelectColumnForm() {
    return {
      name: '',
      alias: '',
      sourceTable: {
        jacksonType: 'simpleTable',
        name: '',
        alias: '',
      },
      aggregateFunction: '',
    };
  },

  buildOrderForm() {
    return {
      fieldName: {
        name: '',
        alias: '',
        sourceTable: {
          jacksonType: 'simpleTable',
          alias: '',
          name: '',
        },
      },
      direction: '',
    };
  },

  buildExpressionForm() {
    return {
      jacksonType: 'condition',
      leftSource: {
        jacksonType: '',
        name: '',
      },
      leftParam: {
        name: '',
        alias: '',
        sourceTable: {
          jacksonType: '',
          name: '',
          alias: '',
        },
        aggregateFunction: '',
      },
      operation: '',
      rightSource: {
        jacksonType: '',
        name: '',
      },
      rightParam: {
        jacksonType: '',
        name: '',
        alias: '',
        sourceTable: {
          jacksonType: '',
          name: '',
          alias: '',
        },
        aggregateFunction: '',
        id: '',
        value: '',
      },
      conditionType: '',
      expression: [],
    };
  },
};
