import { createContext } from 'react';
import { FormikProps } from 'formik';
import { AnyObject } from '../models/AnyObject';

export interface FormikContextValue {
  formik: FormikProps<any>;
  formikEmptyConfig: AnyObject;
  formikConfig: AnyObject;
}

const FormikContext = createContext<FormikContextValue | undefined>(undefined);

export default FormikContext;
