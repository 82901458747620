import React, { FC, useContext, useEffect, useState } from 'react';
import { WidgetProps } from '../../../models/Widget/WidgetProps';
import { PbsDynamicWidgetValue } from '../../../models/Widget/PbsDynamicWidgetValue';
import { valueFromConfig } from '../../../utils/ValueFromOptions';
import { getSelectableData } from '../../../api/resource';
import { ModuleRouteInfo } from '../../../redux/models/state.model';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setRequiredFields } from '../../../redux/reducers/form-builder-reducer';
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import style from './SelectWidget.module.css';
import FormikContext from '../../../contexts/FormikContext';
import { ascFindField, getFieldByFullPath } from '../helpers/formikHelpers';
import { InputWidgetTypesEnum } from '../../../models/Widget/WidgetTypes.enum';
import { useSetDefaultWidgetValue } from '../../../hooks/useSetDefaultWidgetValue';
import useContextByFieldName from '../../../hooks/useContextByFieldName';
import { SelectableFilter } from './models/filter';
import { SelectableFilterOperations } from './models/filter-operations';
import { cloneDeep } from 'lodash';
import { SelectableRequest } from './models/request';
import ActionTypeContext from '../../../contexts/ActionTypeContext';
import { WidgetEvent } from '../../../models/Widget/WidgetEvent';
import { AnyObject } from '../../../models/AnyObject';

const requestBody = {
  filter_info: [
    {
      paramName: 'context',
      type: 'EQUALS',
      paramValue: null,
    },
  ],
  page_info: {
    pageIndex: 0,
    pageSize: 100,
  },
  search_filter: '',
};

const initResponse = {
  data: [],
  total_size: 0,
};

export interface SelectWidgetOptions {
  [key: string]: PbsDynamicWidgetValue;
  filter: PbsDynamicWidgetValue<{ configName: string; type: string; param: string }>;
  label: PbsDynamicWidgetValue<string>;
  isRequired: PbsDynamicWidgetValue<boolean>;
  fieldName: PbsDynamicWidgetValue<string>;
}

const SelectWidget: FC<WidgetProps<SelectWidgetOptions>> = (props) => {
  const [
    label,
    isRequired,
    fieldName,
    parentWidgets,
    endpoint,
    contextFieldPath,
    actions,
    dataSource,
    events,
    contextRepeatable,
    contextWidget,
    filter,
  ] = valueFromConfig(
    props.config?.options,
    'label',
    'isRequired',
    'fieldName',
    'parentWidgets',
    'endpoint',
    'contextFieldPath',
    'actions',
    'dataSource',
    'events',
    'contextRepeatable',
    'contextWidget',
    'filter',
  );

  const dispatch = useDispatch();
  const eventHandler = useContext(ActionTypeContext);
  const formicFromContext = useContext(FormikContext);
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState(initResponse);

  const { moduleKey } = useParams() as unknown as ModuleRouteInfo;

  useSetDefaultWidgetValue(`${props.config.formPath}.${fieldName}`);
  const context = useContextByFieldName(contextFieldPath);

  const parentWidgetsStringValues = (parentWidgets || [])
    .map((widgetField: string) =>
      ascFindField(
        formicFromContext?.formik?.values || {},
        props.config.formPath || '',
        widgetField,
      ),
    )
    .join('');

  const value =
    getFieldByFullPath(
      formicFromContext?.formik?.values || {},
      `${props.config.formPath}.${fieldName}`,
    ) || (props.config.type === InputWidgetTypesEnum.SELECT_MULTIPLE ? [] : undefined);

  useEffect(() => {
    if (contextRepeatable && contextWidget && formicFromContext?.formik?.values) {
      let result: Array<AnyObject> = [];
      setLoading(false);

      const findNestedArray = (obj: AnyObject, field: string) => {
        Object.keys(obj).forEach((key) => {
          const value = obj[key];
          if (field === key && Array.isArray(value)) {
            result = value;
          }
          if (value && typeof value === 'object') {
            findNestedArray(value, field);
          }
        });
      };
      findNestedArray(formicFromContext?.formik?.values || {}, contextRepeatable);

      if (result.length) {
        setResponse({
          ...response,
          data: result
            .map((item: AnyObject) => {
              return {
                title: item[contextWidget],
                id: {
                  id: item[contextWidget],
                },
              };
            })
            .filter((item) => item.id.id) as any,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formicFromContext?.formik?.values]);

  useEffect(() => {
    if (parentWidgets?.length && endpoint) {
      const parentsFilters = parentWidgets.map((widgetField: string) => ({
        paramName: widgetField,
        paramValue: ascFindField(
          formicFromContext?.formik?.values || {},
          props.config.formPath || '',
          widgetField,
        ),
        type: SelectableFilterOperations.EQUALS,
      }));

      const newRequestBody: SelectableRequest = {
        ...requestBody,
        filter_info: setupFilters().concat(parentsFilters),
      };

      getSelectableData(moduleKey, endpoint, newRequestBody)
        .then((data) => {
          setResponse(data);
        })
        .catch(() => setResponse(initResponse))
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentWidgetsStringValues]);

  const setupFilters = (): Array<SelectableFilter> => {
    const filters: Array<SelectableFilter> = [
      {
        paramName: 'context',
        type: SelectableFilterOperations.EQUALS,
        paramValue: context || null,
      },
    ];

    const initialData = cloneDeep(
      getFieldByFullPath(formicFromContext?.formik?.values || {}, `${props.config.formPath}`),
    );

    let filterValue;
    if (filter) {
      const { configName, type, param } = filter;
      if (configName || type || param) {
        filterValue = {
          configName,
          type,
          param,
        };
      }
    } else if (initialData) {
      filterValue = initialData;
    }
    const initDataFilter: SelectableFilter = {
      paramName: 'id',
      type: SelectableFilterOperations.EQUALS,
      paramValue: filterValue,
    };
    filters.push(initDataFilter);

    return filters;
  };

  useEffect(() => {
    if (!parentWidgets?.length && endpoint) {
      getSelectableData(moduleKey, endpoint, { ...requestBody, filter_info: setupFilters() })
        .then((data) => {
          setResponse(data);
        })
        .catch(() => setResponse(initResponse))
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context]);

  useEffect(() => {}, [response]);

  useEffect(() => {
    if (isRequired) {
      dispatch(setRequiredFields(fieldName));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.config]);

  return (
    <div className={style.selectContainer}>
      <Box display="flex" alignItems="center">
        <InputLabel>{label}</InputLabel>
        {isRequired && (
          <Typography variant="inherit" color="error">
            *
          </Typography>
        )}
      </Box>
      <FormControl style={{ width: '100%' }}>
        {formicFromContext?.formik ? (
          <Select
            size="small"
            className={style.selectField}
            labelId="select-label"
            id={`${props.config.formPath}.${fieldName}`}
            name={`${props.config.formPath}.${fieldName}`}
            value={value}
            onChange={(e) => {
              const clickAction = (events || []).find(
                (event: WidgetEvent) => event.eventType === 'ON_CLICK',
              );
              if (clickAction) {
                const value: any = e.target.value;
                if (value.configName || value.id || value.type) {
                  const { configName, id, type } = value;
                  eventHandler(clickAction.actions[0], [configName, id, type]);
                }
              }
              formicFromContext.formik.handleChange(e);
            }}
            disabled={loading}
            multiple={props.config.type === InputWidgetTypesEnum.SELECT_MULTIPLE}
            displayEmpty
            onBlur={formicFromContext!.formik.handleBlur}
            error={
              formicFromContext!.formik.touched[fieldName] &&
              Boolean(formicFromContext!.formik.errors[fieldName])
            }
          >
            {loading ? (
              <MenuItem disabled>
                <CircularProgress size={24} />
              </MenuItem>
            ) : response.data?.length ? (
              response.data.map((option: any) => (
                <MenuItem key={option.title} value={option.id.id} className={style.menuItem}>
                  {option.title}
                </MenuItem>
              ))
            ) : (
              <MenuItem key={'noData'} disabled={true} className={style.menuItem}>
                Нет данных
              </MenuItem>
            )}
          </Select>
        ) : (
          <Select
            size="small"
            className={style.selectField}
            labelId="select-label"
            disabled={loading}
            multiple={props.config.type === InputWidgetTypesEnum.SELECT_MULTIPLE}
            displayEmpty
          >
            {response.data?.length ? (
              response.data.map((option: any) => (
                <MenuItem key={option.title} value={option.id.id} className={style.menuItem}>
                  {option.title}
                </MenuItem>
              ))
            ) : (
              <MenuItem key={'noData'} disabled={true} className={style.menuItem}>
                Нет данных[
              </MenuItem>
            )}
          </Select>
        )}
      </FormControl>
    </div>
  );
};
export default SelectWidget;
