import { Divider } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import ThreeCustomizingComponent from '../TreeCustomizing/ThreeCustomizing.component';
import ButtonCustomizingComponent from '../ButtonCustomizing/ButtonCustomizing.component';
import GlobalCustomizingComponent from '../GlobalCustomizing/GlobalCustomizing.component';
import ThemeModalWrapper from '../ThemeModalWrapper/ThemeModalWrapper.component';
import {
  ButtonThemeConfig,
  ConfigurationThemeConfig,
  GlobalTokenThemeConfig,
  TreeThemeConfig,
} from '../../../redux/models/ConfigurationThemeConfig';
import { useCookies } from 'react-cookie';
import { StateModel } from '../../../redux/models/state.model';
import { useDispatch, useSelector } from 'react-redux';
import {
  buttonThemeSated,
  globalThemeSated,
  treeThemeSated,
} from '../../../redux/reducers/theme-reducer';

export interface ThemeConfigPageProps {
  fullTheme: ConfigurationThemeConfig;
  globalThemeSettings: GlobalTokenThemeConfig;
  treeThemeSettings: TreeThemeConfig;
  buttonThemeSettings: ButtonThemeConfig;
}

export interface ThemeConfigPageCallbacks {
  setGlobalTheme: (theme: GlobalTokenThemeConfig) => void;
  setTreeTheme: (theme: TreeThemeConfig) => void;
  setButtonTheme: (theme: ButtonThemeConfig) => void;
}

const stateMapper = (state: StateModel): ThemeConfigPageProps => {
  return {
    fullTheme: state?.theme || {},
    globalThemeSettings: state?.theme?.token || {},
    treeThemeSettings: state?.theme?.components?.Tree || {},
    buttonThemeSettings: state?.theme?.components?.Button || {},
  };
};

const ThemeConfigPage: FC = (props) => {
  const { fullTheme, globalThemeSettings, treeThemeSettings, buttonThemeSettings } =
    useSelector(stateMapper);

  const dispatch = useDispatch();

  const [customTree, setCustomTree] = useState<TreeThemeConfig>({ ...buttonThemeSettings });
  const [customButton, setCustomButton] = useState<ButtonThemeConfig>({ ...treeThemeSettings });
  const [customGlobal, setCustomGlobal] = useState<GlobalTokenThemeConfig>({
    ...globalThemeSettings,
  });

  const [cookies, setCookies] = useCookies();

  useEffect(() => {
    setCookies('theme', JSON.stringify(fullTheme));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullTheme]);

  const submitTreeSettings = () => dispatch(treeThemeSated(customTree));
  const submitButtonSettings = () => dispatch(buttonThemeSated(customButton));
  const submitGlobalSettings = () => dispatch(globalThemeSated(customGlobal));

  return (
    <>
      <ThemeModalWrapper
        title="Выбор стилей дерева"
        submitAction={submitTreeSettings}
        content={
          <ThreeCustomizingComponent onCustomTreeChange={setCustomTree} fullTheme={fullTheme} />
        }
      />
      <Divider />
      <ThemeModalWrapper
        title="Выбор стилей кнопок"
        submitAction={submitButtonSettings}
        content={
          <ButtonCustomizingComponent
            onCustomButtonChange={setCustomButton}
            fullTheme={fullTheme}
          />
        }
      />
      <Divider />
      <ThemeModalWrapper
        title="Выбор глобальных стилей"
        submitAction={submitGlobalSettings}
        content={
          <GlobalCustomizingComponent
            onCustomGlobalChange={setCustomGlobal}
            fullTheme={fullTheme}
          />
        }
      />
    </>
  );
};

export default ThemeConfigPage;
