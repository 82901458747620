export enum EventTypes {
  ON_CLICK = 'ON_CLICK',
  ON_SELECT = 'ON_SELECT',
}

export enum LoadActions {
  // LOAD_AND_DISPLAY_FORM_ON_PAGE = 'LOAD_AND_DISPLAY_FORM_ON_PAGE', точно ли загрузка ?
  LOAD_DATA_AND_DISPLAY_FORM_ON_PAGE = 'LOAD_DATA_AND_DISPLAY_FORM_ON_PAGE',
  LOAD_DATA_AND_DISPLAY_FORM_PAGE = 'LOAD_DATA_AND_DISPLAY_FORM_PAGE',
  LOAD_DATA_AND_DISPLAY_FORM = 'LOAD_DATA_AND_DISPLAY_FORM',
  LOAD_AND_DISPLAY_WIDGET = 'LOAD_AND_DISPLAY_WIDGET',
}

export enum ActionTypes {
  NO_ACTION = 'NO_ACTION',
  UPDATE_DATA = 'UPDATE_DATA',
  UPDATE_FORM_DATA = 'UPDATE_FORM_DATA',
  DISPLAY_WIDGET = 'DISPLAY_WIDGET',
  LOAD_AND_DISPLAY_WIDGET = 'LOAD_AND_DISPLAY_WIDGET',
  DISPLAY_FORM = 'DISPLAY_FORM',
  DISPLAY_FORM_PAGE = 'DISPLAY_FORM_PAGE',
  LOAD_AND_DISPLAY_FORM_ON_PAGE = 'LOAD_AND_DISPLAY_FORM_ON_PAGE',
  LOAD_DATA_AND_DISPLAY_FORM_ON_PAGE = 'LOAD_DATA_AND_DISPLAY_FORM_ON_PAGE',
  LOAD_DATA_AND_DISPLAY_FORM_PAGE = 'LOAD_DATA_AND_DISPLAY_FORM_PAGE',
  LOAD_DATA_AND_DISPLAY_FORM = 'LOAD_DATA_AND_DISPLAY_FORM',
  SUBMIT_FORM = 'SUBMIT_FORM',
  SUBMIT_FORM_AND_WAIT = 'SUBMIT_FORM_AND_WAIT',
  SUBMIT_FORM_PAGE = 'SUBMIT_FORM_PAGE',
  CANCEL_FORM = 'CANCEL_FORM',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  RELOAD_FORM_ON_PAGE = 'RELOAD_FORM_ON_PAGE',
  DELETE = 'DELETE',
  SESSION_STORAGE_SET = 'SESSION_STORAGE_SET',
  SESSION_STORAGE_CLEAR = 'SESSION_STORAGE_CLEAR',
  HIGHLIGHT_WIDGET = 'HIGHLIGHT_WIDGET',
  GET_TABLE_DATA_REQUEST = 'GET_TABLE_DATA_REQUEST',
  RESET_DYNAMIC_PAGE = 'RESET_DYNAMIC_PAGE',
  CHANGE_FORM_STATE = 'CHANGE_FORM_STATE',
  DOWNLOAD_FILE = 'DOWNLOAD_FILE',
  LOAD_DATA_AND_DISPLAY_PAGE = 'LOAD_DATA_AND_DISPLAY_PAGE',
  CANCEL_PAGE = 'CANCEL_PAGE',
  REDIRECT = 'REDIRECT',
  PRESS_CHECKBOX = 'PRESS_CHECKBOX',
}

export enum RowButtonTypes {
  EXECUTE_ROW_BUTTON = 'EXECUTE_ROW_BUTTON',
  EDIT_ROW_BUTTON = 'EDIT_ROW_BUTTON',
  DELETE_ROW_BUTTON = 'DELETE_ROW_BUTTON',
  VIEW_ROW_BUTTON = 'VIEW_ROW_BUTTON',
  // DOWNLOAD_ROW_DATA = "DOWNLOAD_ROW_DATA", //unused
  CHECKBOX_ROW_BUTTON = 'CHECKBOX_ROW_BUTTON',
  DOWNLOAD_ROW_BUTTON = 'DOWNLOAD_ROW_BUTTON',
  ACCEPT_ROW_BUTTON = 'ACCEPT_ROW_BUTTON',
  REJECT_ROW_BUTTON = 'REJECT_ROW_BUTTON',
  TERMINAL_ROW_BUTTON = 'TERMINAL_ROW_BUTTON',
}
