import { FormInnerGridOptions } from '../FormInnerGrid/FormInnerGrid';
import { AreasConfig } from '../../../models/Page/AreasConfig';
import { InputWidgetTypesEnum, WidgetTypesEnum } from '../../../models/Widget/WidgetTypes.enum';
import { cloneDeep } from 'lodash';
import { AnyObject } from '../../../models/AnyObject';

const getFieldDefaultValue = (type: InputWidgetTypesEnum): any => {
  switch (type) {
    case InputWidgetTypesEnum.SELECT_MULTIPLE:
      return [];
    case InputWidgetTypesEnum.SELECT:
    case InputWidgetTypesEnum.CODE_EDITOR_GROOVY:
      return '';
    case InputWidgetTypesEnum.INPUT_BOOLEAN:
      return false;
    case InputWidgetTypesEnum.VIEW_CONSTRUCTOR:
      return {
        gridTemplate: ['.'],
        areasConfig: [],
        rowSize: ['1rf'],
        columnSize: ['1fr'],
      };
    default:
      return undefined;
  }
};

export const clearArrayFields = (object: AnyObject) => {
  const result = cloneDeep(object);

  Object.keys(result).forEach((key) => {
    if (Array.isArray(result[key]) && typeof result[key][0] === 'object') {
      result[key] = [];
    }
    if (typeof result[key] === 'object' && Object.keys(result[key]).length) {
      result[key] = clearArrayFields(result[key]);
    }
  });
  return result;
};

export const getFieldByFullPath = (object: AnyObject, path: string): any => {
  return path.split('.').reduce((acc, cur) => {
    return acc?.[cur];
  }, object);
};

export const ascFindField = (object: AnyObject, path: string, field: string) => {
  const pathArray = path.split('.');
  let result: any = undefined;
  while (pathArray.length) {
    const value = getFieldByFullPath(object, pathArray.join('.'));
    result = value?.[field];
    if (result !== undefined) {
      break;
    }
    pathArray.pop();
  }
  return result;
};

export const createFormFields = (options: FormInnerGridOptions): { [key: string]: any } => {
  const result: { [key: string]: any } = { [options.fieldName.value]: {} };

  const configureFieldsByAreasConfig = (config: Array<AreasConfig>, path: string): void => {
    config.forEach((conf) => {
      const subObject = getFieldByFullPath(result, path);
      const { options } = conf.widgetConfig;
      if (InputWidgetTypesEnum[conf.widgetConfig.type as InputWidgetTypesEnum]) {
        const defaultValue = getFieldDefaultValue(conf.widgetConfig.type as InputWidgetTypesEnum);
        if (Array.isArray(subObject)) {
          subObject[0][options.fieldName.value] = defaultValue;
        } else {
          subObject[options.fieldName.value] = defaultValue;
        }
      }
      if (
        conf.widgetConfig.type === WidgetTypesEnum.FORMS_INNER_GRID ||
        conf.widgetConfig.type === WidgetTypesEnum.PAGE_VIEW
      ) {
        if (Array.isArray(subObject)) {
          subObject[0][options.fieldName.value] = (options as AnyObject)?.isRepeatable?.value
            ? [{}]
            : {};
        } else {
          subObject[options.fieldName.value] = (options as AnyObject)?.isRepeatable?.value
            ? [{}]
            : {};
        }
        const newPath = (options as AnyObject)?.isRepeatable?.value
          ? `${path}.${conf.widgetConfig.options.fieldName.value}.0`
          : `${path}.${conf.widgetConfig.options.fieldName.value}`;
        configureFieldsByAreasConfig(
          conf.widgetConfig?.options?.innerGridConfig?.value?.areasConfig || [],
          newPath,
        );
      }
    });
  };

  if (options.innerGridConfig.value.areasConfig?.length) {
    configureFieldsByAreasConfig(
      options.innerGridConfig.value.areasConfig,
      options.fieldName.value,
    );
  }

  return result;
};
