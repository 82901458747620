import { AccountCircle, Person, Settings } from '@mui/icons-material';
import { Dropdown, MenuProps, Space } from 'antd';
import { Link } from 'react-router-dom';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { environment } from '../../environments/environments';
import { useSelector } from 'react-redux';
import { StateModel } from '../../redux/models/state.model';
import React, { useEffect } from 'react';
import { fetchUsername } from '../../redux/reducers/authInfo-reducer';
import { useAppDispatch } from '../../redux/store';

const DropdownMenu = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const token = sessionStorage.getItem('token');

    if (token) {
      dispatch(fetchUsername(token));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const authorized: boolean = useSelector((state: StateModel) => state.authInfo.authorized);
  const userName: string = useSelector((state: StateModel) => state.authInfo.username) ?? 'empty';

  const exitClickHandler = () => {
    const logoutURL = environment.logout;
    sessionStorage.clear();
    window.location.href = logoutURL;
  };

  const loginClickHandler = () => {
    const loginURL = environment.address + environment.login;
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('expires_in');
    sessionStorage.removeItem('refresh_token');
    window.location.href = loginURL;
  };

  const items: MenuProps['items'] = [
    {
      itemIcon: <Settings />,
      label: <Link to="/app/module/theme">Настройки</Link>,
      key: 0,
      style: { flexFlow: 'row-reverse' },
    },
    {
      itemIcon: <Person />,
      label: <Link to="/app/module">Профиль</Link>,
      key: 1,
      style: { flexFlow: 'row-reverse' },
    },
    {
      type: 'divider',
    },
    {
      itemIcon: <ExitToAppIcon />,
      label: <span onClick={exitClickHandler}>Выход</span>,
      key: 2,
      style: { flexFlow: 'row-reverse' },
    },
  ];

  return authorized ? (
    <Dropdown menu={{ items }} trigger={['click']}>
      <Space>
        <p>{userName}</p>
        <AccountCircle />
      </Space>
    </Dropdown>
  ) : (
    <span onClick={loginClickHandler}>Вход</span>
  );
};

export default DropdownMenu;
