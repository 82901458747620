import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { WidgetProps } from '../../../models/Widget/WidgetProps';
import {
  executeDownloadAction,
  getWidgetDynamicData,
  importTypeConfig,
} from '../../../api/resource';
import { valueFromConfig } from '../../../utils/ValueFromOptions';
import { ModuleRouteInfo, StateModel } from '../../../redux/models/state.model';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { WidgetSuccessAction } from '../../../models/Widget/WidgetEventAction';
import { ConfigType } from '../../../models/ConfigType';
import { ConfigColumn, TableWidgetAction } from '../../../models/Widget/WidgetTableConfig';
import { PbsDynamicWidgetValue } from '../../../models/Widget/PbsDynamicWidgetValue';
import { DataFunction } from '../../../models/Widget/DataFunction';
import { ActionTypes, RowButtonTypes } from '../../../models/enums/EventTypes.enum';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { setModalState } from '../../../redux/reducers/modal-state-reducer';
import { useAppDispatch } from '../../../redux/store';
import { resetData } from '../../../redux/reducers/ActionsReducer';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import WidthCalculateContext from '../../../contexts/WidthCalculateContext';
import FormContext from '../../../contexts/FormContextType';
import { processValue } from '../../../utils/ProcessFunction';
import ActionTypeContext from '../../../contexts/ActionTypeContext';

export interface TableWidgetOptions {
  // TODO разобраться с типом
  [key: string]: PbsDynamicWidgetValue;

  actions: PbsDynamicWidgetValue<Array<TableWidgetAction>>;
  columns: PbsDynamicWidgetValue<Array<ConfigColumn>>;
  dataSource: PbsDynamicWidgetValue<Array<DataFunction>>;
  fieldName: PbsDynamicWidgetValue<string>;
}
interface RowDataItem {
  [key: string]: string | ConfigType | null;
}
const TableWidget: FC<WidgetProps<TableWidgetOptions>> = (props) => {
  const dispatch = useAppDispatch();

  const [actions, columns, dataSource, fieldName] = valueFromConfig(
    props.config?.options,
    'actions',
    'columns',
    'dataSource',
    'fieldName',
  );

  const moduleRouteInfo: ModuleRouteInfo = useSelector(
    (state: StateModel) => state.moduleRouteInfo,
  );
  const successActions: Array<WidgetSuccessAction> = useSelector(
    (state: StateModel) => state.successActions,
  );

  const { moduleKey } = moduleRouteInfo;

  const navigate = useNavigate();

  const ref = useRef<any>(null);

  const parentWidth = useContext(WidthCalculateContext);
  const eventHandler = useContext(ActionTypeContext);
  const formContext = useContext(FormContext);

  const [tableData, setTableData] = useState<TableWidgetOptions | null>();
  const [loading, setLoading] = useState<boolean>(false);
  const [mappedColumns, setMappedColumns] = useState<Array<GridColDef>>([]);
  const [rowData, setRowData] = useState({});
  const [autoPercent, setAutoPercent] = useState<number>(15);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => loadTableData(), [props.config, rowData]);

  useEffect(() => {
    if (successActions.length) {
      successActions
        .filter(
          (a: WidgetSuccessAction) => a.targetArea === fieldName || a.targetArea === props.areaName,
        )
        .forEach((action: WidgetSuccessAction) => {
          switch (action.actionType) {
            case ActionTypes.UPDATE_DATA: {
              loadTableData();
              break;
            }
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successActions]);

  const onRowButtonClick = (action: ActionTypes, context: ConfigType) => {
    const parts = context?.configName.split('/');
    let link: string;

    const { configName, id, type } = context;
    switch (action) {
      case 'PRESS_CHECKBOX':
        importTypeConfig(context).then((data) => {
          setRowData((prevData) => ({
            ...prevData,
            [data?.data?.value?.typeName]: context.type,
          }));
        });
        break;
      case 'LOAD_DATA_AND_DISPLAY_PAGE': {
        if (formContext.setContext) {
          formContext.setContext({ ...context, configName: parts[1] });
        }
        link = `/app/module/${parts[0]}/PAGE/${parts[1]}/${id}/${type}`;
        navigate(`${link}`, { relative: 'path' });
        break;
      }
      case 'LOAD_DATA_AND_DISPLAY_FORM_PAGE':
      case 'DISPLAY_FORM_PAGE': {
        if (formContext.setContext) {
          formContext.setContext(context);
        }
        link = `${configName}/${id}/${type}`;
        navigate(`${link}`, { relative: 'path' });
        break;
      }
      case 'LOAD_DATA_AND_DISPLAY_FORM':
        dispatch(setModalState({ isOpen: true, context: context, action: action }));
        eventHandler({ actionType: action });
        break;
      case 'DISPLAY_FORM':
        dispatch(setModalState({ isOpen: true, context: context, action: action }));
        break;
      case 'DOWNLOAD_FILE': {
        const requestBody = {};
        executeDownloadAction(
          moduleKey,
          {
            configName: context.configName,
            moduleName: moduleKey,
            id: context.id,
            fileUID: context.id,
            type: context.type,
            actionName: null,
          },
          requestBody,
        ).then((data) => {
          const name = data.headers.get('content-disposition').split(';')[1].split('=')[1];
          const blob = data.data as Blob;
          const a = document.createElement('a');
          a.download = name;
          a.href = URL.createObjectURL(blob);
          a.click();
          a.remove();
        });
        break;
      }
      default:
    }
  };

  const loadTableData = () => {
    setLoading(true);
    const loadAction = (actions || []).find(
      (action: TableWidgetAction) => action.actionType === 'GET_TABLE_DATA_REQUEST',
    );

    const httpParams = {
      id: loadAction.options?.id,
      type: loadAction.options?.type,
      configName: loadAction.options?.configName,
      fieldName: fieldName,
    };

    const requestBody = {
      filter_info: [
        {
          paramName: 'string',
          paramValue: {},
          type: 'EQUALS',
        },
      ],
      search_filter: 'string',
      page_info: {
        pageIndex: 0,
        pageSize: 3000,
      },
    };

    getWidgetDynamicData<TableWidgetOptions>(moduleKey, httpParams, requestBody)
      .then((response) => {
        setTableData(response);
      })
      .catch((err) => {
        console.error('Error fetching data:', err);
      })
      .finally(() => {
        dispatch(resetData(successActions));
        setLoading(false);
      });
    setTableData(null);
  };

  const mapColumns = (columns: Array<ConfigColumn>): Array<GridColDef> => {
    return columns.map((column: ConfigColumn, index: number) => {
      const widthNumber: number =
        ((ref.current.offsetWidth - 2) *
          (column.width === 'auto' ? autoPercent : parseInt(column.width))) /
        100;

      if (column.actionExecutable && (column.title || column.executeConfig)) {
        return {
          field: processValue(column.fieldName, column?.options?.dataSource || column.dataSource),
          headerName: column.title,
          width: widthNumber,
          renderCell: (params) => {
            const record = params.formattedValue;
            return (
              <div key={column.fieldName}>
                {column.executeConfig?.type === 'CHECKBOX_ROW_BUTTON' ? (
                  <Checkbox
                    onChange={() => onRowButtonClick(column.executeConfig?.actionType, record)}
                    checked={getTableDataRowChecked(record.id)}
                  />
                ) : (
                  <IconButton
                    onClick={() => onRowButtonClick(column.executeConfig?.actionType, record)}
                  >
                    {getButtonIcon(column.executeConfig?.type)}
                  </IconButton>
                )}
              </div>
            );
          },
        };
      } else {
        return {
          field: processValue(column.fieldName, column?.options?.dataSource || column.dataSource),
          headerName: `${column.title}`,
          width: widthNumber,
          renderCell: (params: any) => {
            const { formattedValue, field, row } = params;
            // Ужас. С беком нужно прийти к единой модели
            const dataSource = Array.isArray(column?.options?.dataSource)
              ? column?.options?.dataSource
              : Array.isArray((column?.options?.dataSource as any)?.value)
                ? (column?.options?.dataSource as any)?.value
                : column.dataSource || [];

            return (
              <>{`${field ? formattedValue : processValue(row, dataSource, fieldName === 'priority')}`}</>
            );
          },
        };
      }
    });
  };

  const getTableDataRowChecked = (title: string) => {
    if (tableData) {
      const rowData = tableData.data.tableData.find((item: RowDataItem) => item.title === title);
      return rowData && rowData.importRowButton?.type === 'checked';
    }
    return false;
  };

  const calculateAutoColumns = (columns: Array<any>) => {
    let numberOfAuto = 0;
    const calculatedWidth = columns.reduce((acc, col) => {
      if (col.width === 'auto') {
        numberOfAuto += 1;
        return acc;
      }

      const width = parseInt(col.width);
      if (!Number.isNaN(width)) {
        acc += width;
      }
      return acc;
    }, 0);

    if (numberOfAuto && calculatedWidth < 100) {
      setAutoPercent((100 - calculatedWidth) / numberOfAuto);
    }
  };

  useEffect(() => {
    if (ref.current?.offsetWidth) {
      calculateAutoColumns(columns);
      const newColumns = mapColumns(columns);
      setMappedColumns(newColumns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, tableData, ref.current?.offsetWidth]);

  const getButtonIcon = (types: RowButtonTypes) => {
    switch (types) {
      case RowButtonTypes.VIEW_ROW_BUTTON:
        return <VisibilityIcon />;
      case RowButtonTypes.DOWNLOAD_ROW_BUTTON:
        return <DownloadIcon />;
      case RowButtonTypes.ACCEPT_ROW_BUTTON:
        return <div>Accept</div>;
      case RowButtonTypes.DELETE_ROW_BUTTON:
        return <DeleteIcon />;
      case RowButtonTypes.EDIT_ROW_BUTTON:
        return <EditIcon />;
      case RowButtonTypes.EXECUTE_ROW_BUTTON:
        return <div>Execute</div>;
      case RowButtonTypes.REJECT_ROW_BUTTON:
        return <div>Reject</div>;
      case RowButtonTypes.TERMINAL_ROW_BUTTON:
        return <div>Terminal</div>;
      default:
        return types;
    }
  };

  const [widthWidget, setWidthWidget] = useState<number>();

  useEffect(() => {
    const result = (parentWidth * 0.785) / 2; // triggers rerender of dataGrid
    setWidthWidget(result);
  }, [parentWidth]);

  return (
    <>
      <DataGrid
        ref={ref}
        sx={{
          width: widthWidget,
          height: '380px',
        }}
        loading={loading}
        rows={
          tableData?.data?.tableData
            ? tableData.data.tableData.map((item: TableWidgetOptions, index: number) => ({
                ...item,
                id: index,
              }))
            : []
        }
        localeText={{ noRowsLabel: 'Данных нет' }}
        initialState={{ pagination: { paginationModel: { pageSize: 5 } } }}
        columns={mappedColumns}
        pageSizeOptions={[5, 10, 25]}
      />
    </>
  );
};

export default TableWidget;
