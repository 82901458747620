import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getDataInstance } from '../../api/resource';
import { WidgetData } from '../../models/Widget/WidgetData';

export const fetchDataModalInstance = createAsyncThunk(
  'data/modal',
  async ({ moduleKey, activeAction }: { moduleKey: string; activeAction: any }) => {
    const res = await getDataInstance(moduleKey, activeAction);
    return res;
  },
);

export const fetchDataPageInstance = createAsyncThunk(
  'data/page',
  async ({ moduleKey, activeAction }: { moduleKey: string; activeAction: any }) => {
    const res = await getDataInstance(moduleKey, activeAction);
    return res;
  },
);

const initialState: any = {};

const dataInstanceSlice = createSlice({
  name: 'dataInstance',
  initialState,
  reducers: {
    resetDataInstance(state) {
      state = initialState;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDataModalInstance.pending, (state: WidgetData, action) => {});
    builder.addCase(fetchDataModalInstance.fulfilled, (state: WidgetData, action) => {
      return action.payload;
    });
    builder.addCase(fetchDataModalInstance.rejected, (state: WidgetData, action) => {
      return null;
    });
    builder.addCase(fetchDataPageInstance.pending, (state: WidgetData, action) => {});
    builder.addCase(fetchDataPageInstance.fulfilled, (state: WidgetData, action) => {
      return action.payload;
    });
    builder.addCase(fetchDataPageInstance.rejected, (state: WidgetData, action) => {
      return null;
    });
  },
});

export const { resetDataInstance } = dataInstanceSlice.actions;

const DataInstanceReducer = dataInstanceSlice.reducer;

export default DataInstanceReducer;
