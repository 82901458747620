import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from '@mui/material/Button';
import { SqlBuilderForms } from '../../../../utils/sqlBuilderForms';
import cleanDeep from '../../../../utils/cleenDeep';
import { useAppDispatch } from '../../../../redux/store';
import { updateFormValues } from '../../../../redux/reducers/sqlStateServiceReducer';
import { useSelector } from 'react-redux';
import { ModuleRouteInfo, StateModel } from '../../../../redux/models/state.model';
import { InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { getSelectableData } from '../../../../api/resource';
import { updateAvailableSelectColumns } from '../../../../redux/reducers/sqlConstructorReducer';
import { useParams } from 'react-router-dom';

type Props = {
  formState: any;
  formKey: string;
};

const SelectComponent: React.FC<Props> = ({ formState, formKey }) => {
  const dispatch = useAppDispatch();
  const { moduleKey } = useParams() as unknown as ModuleRouteInfo;

  const formValues = SqlBuilderForms.buildSelectColumnForm();

  const validationSchema = yup.object().shape({});

  const selectedTables = useSelector((state: StateModel) => state.sqlConstructor.selectedTables);
  const availableSelectColumns = useSelector(
    (state: StateModel) => state.sqlConstructor.availableSelectColumns,
  );

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const formatForm = cleanDeep(values);
      dispatch(updateFormValues({ values: formatForm, formKey }));
      formik.resetForm();
    },
  });

  useEffect(() => {
    const requestBody = {
      filter_info: [
        {
          paramName: 'table',
          type: 'EQUALS',
          paramValue: formik.values?.sourceTable?.name,
        },
      ],
      page_info: {
        pageIndex: 0,
        pageSize: 10,
      },
      search_filter: '',
    };
    getSelectableData(moduleKey, 'getTableFields', requestBody)
      .then((data: any) => {
        dispatch(updateAvailableSelectColumns(data.data));
      })
      .catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.sourceTable]);

  useEffect(() => {
    formik.setValues(formState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <InputLabel>Table</InputLabel>
        <Select
          fullWidth
          name="sourceTable"
          value={formik.values?.sourceTable?.alias || formik.values?.sourceTable?.name || ''}
          onChange={(event) => {
            const selectedTable = selectedTables.find(
              (table: any) =>
                table.alias === event.target.value || table.name === event.target.value,
            );
            if (selectedTable) {
              formik.setFieldValue('sourceTable', {
                jacksonType: 'simpleTable',
                name: selectedTable.name,
                alias: selectedTable.alias,
              });
            } else {
              formik.setFieldValue('sourceTable', {
                jacksonType: 'simpleTable',
                name: event.target.value,
                alias: '',
              });
            }
          }}
          onBlur={formik.handleBlur}
          error={formik.touched.sourceTable && Boolean(formik.errors.sourceTable)}
        >
          {selectedTables.map((table: any, index: number) => (
            <MenuItem key={index} value={table.alias || table.name}>
              {table.alias ? table.alias : table.name}
            </MenuItem>
          ))}
        </Select>

        <InputLabel>Column</InputLabel>
        <Select
          fullWidth
          name="name"
          value={formik.values?.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.name && Boolean(formik.errors.name)}
        >
          {availableSelectColumns &&
            availableSelectColumns.map((column: any) => (
              <MenuItem key={column.id.id} value={column.id.id}>
                {column.title}
              </MenuItem>
            ))}
        </Select>
        <InputLabel>Alias</InputLabel>
        <TextField
          fullWidth
          id="alias"
          name="alias"
          value={formik.values?.alias}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <Button color="primary" variant="contained" fullWidth type="submit">
          Submit
        </Button>
      </form>
    </>
  );
};

export default SelectComponent;
