import axios from '../axiosInterceptor';
import { ModuleInfo } from '../models/ModuleInfo';
import { AxiosResponse, GenericAbortSignal } from 'axios';
import { ModuleMenuItem } from '../models/ModuleMenuItem';
import { DynamicPageViewConfig } from '../models/Page/DynamicPageViewConfig';
import { WidgetData } from '../models/Widget/WidgetData';
import { GetDataInstanceParams } from '../models/GetDataInstanceParams';
import {
  ExecutePageActionParams,
  GetWidgetDynamicDataBody,
  GetWidgetDynamicDataParams,
} from '../models/GetWidgetDynamicDataParams';

export const getModules = (): Promise<AxiosResponse<Array<ModuleInfo>>> => {
  return axios.get('/pbs/api/base/modules/modules');
};

export const getModuleChildren = (
  moduleName: string,
): Promise<AxiosResponse<Array<ModuleMenuItem>>> => {
  return axios.get(`/pbs/modules/${moduleName}/base/v1/menuItems`);
};

export const loadPageAbout = async (): Promise<WidgetData> => {
  const response = await axios.get<WidgetData>(`/pbs/modules/USERS_MODULE/base/v1/versionInfo`);
  return response.data;
};

export const getToken = (code: string) => {
  const redirect_uri = window.location.origin + '/callback';
  const params = new URLSearchParams();
  params.set('grant_type', 'authorization_code');
  params.set('redirect_uri', redirect_uri);
  params.set('code', code);

  return axios.post('/oauth2/token', params.toString(), {
    headers: { 'Content-type': 'application/x-www-form-urlencoded' },
  });
};

export const fetchRefreshToken = (refreshToken: string) => {
  const params = new URLSearchParams();
  params.set('grant_type', 'refresh_token');
  params.set('refresh_token', refreshToken);
  return axios.post('/oauth2/token', params.toString(), {
    headers: { 'Content-type': 'application/x-www-form-urlencoded' },
  });
};

export const getUserName = (token: string): Promise<AxiosResponse<any>> => {
  const introspect: URLSearchParams = new URLSearchParams();
  introspect.set('token', token);
  return axios.post(`/oauth2/introspect?${introspect.toString()}`, null);
};

export const loadPageConfig = (
  moduleKey: string,
  params: GetDataInstanceParams,
  abortSignal?: GenericAbortSignal,
): Promise<DynamicPageViewConfig> => {
  return axios
    .get(`/pbs/modules/${moduleKey}/base/v1/config`, {
      params: params,
      signal: abortSignal || undefined,
    })
    .then((response) => response.data);
};

/**
 Получение данных для формы или страницы (при изменении сущности при помощи формы
 или при открытии страницы после нажатию на дерево)
 */
export const getDataInstance = (
  moduleKey: string,
  params: GetDataInstanceParams,
  abortSignal?: GenericAbortSignal,
): Promise<WidgetData> => {
  return axios
    .get(`/pbs/modules/${moduleKey}/base/v1/data`, { params, signal: abortSignal || undefined })
    .then((response) => {
      return response.data;
    });
};

/** Создание, изменение данных при помощи формы */
export const executePageAction = (
  moduleKey: string,
  params: ExecutePageActionParams,
  body: any,
): Promise<any> => {
  const data = body || {};
  return axios.post(`/pbs/modules/${moduleKey}/base/v1/executePageAction`, data, { params });
};

export const executeDownloadAction = (
  moduleKey: string,
  params: ExecutePageActionParams | any,
  body: any,
): Promise<any> => {
  const data = body || {};
  return axios.get(`/pbs/modules/${moduleKey}/base/v1/download`, { params, responseType: 'blob' });
};

/**
 Получение данных для отдельных виджетов (дерево, таблица)
 */
export const getWidgetDynamicData = <T = unknown>(
  moduleKey: string,
  params: GetWidgetDynamicDataParams,
  body: GetWidgetDynamicDataBody | null,
): Promise<T> => {
  const data = body || {};
  return axios
    .post(`/pbs/modules/${moduleKey}/base/v1/dynamicData`, data, { params })
    .then((response) => response.data);
};

export const importTypeConfig = (body: any): Promise<any> => {
  return axios.post<any>(`/pbs/api/export/importTypeConfig`, body);
};

export const getSelectableData = (
  nodeName: string,
  endpoint: string,
  requestBody: any,
): Promise<any> => {
  return axios
    .post<any>(`/pbs/modules/${nodeName}/base/v1/selectable/${endpoint}`, requestBody)
    .then((response) => response.data);
};
