import ModulesReducer from './reducers/modules-reducer';
import { StateModel } from './models/state.model';
import { configureStore } from '@reduxjs/toolkit';
import AuthInfoReducer from './reducers/authInfo-reducer';
import ModuleRouteReducer from './reducers/module-route-info-reducer';
import ThemeReducer from './reducers/theme-reducer';
import { useDispatch } from 'react-redux';
import FormDataReducer from './reducers/form-builder-reducer';
import ModalStateReducer from './reducers/modal-state-reducer';
import ActionsReducer from './reducers/ActionsReducer';
import DataInstanceReducer from './reducers/DataInstanceReducer';
import notificationMessageReducer from './reducers/notificationMesageReducer';
import sqlStateServiceReducer from './reducers/sqlStateServiceReducer';
import sqlConstructorReduce from './reducers/sqlConstructorReducer';

const store = configureStore<StateModel>({
  reducer: {
    modules: ModulesReducer,
    authInfo: AuthInfoReducer,
    moduleRouteInfo: ModuleRouteReducer,
    theme: ThemeReducer,
    formsBuilder: FormDataReducer,
    modalState: ModalStateReducer,
    successActions: ActionsReducer,
    dataInstance: DataInstanceReducer,
    notificationMessage: notificationMessageReducer,
    sqlConstructor: sqlConstructorReduce,
    sqlStateService: sqlStateServiceReducer,
  },
});

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
