import React, { useEffect } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  RouterProvider,
} from 'react-router-dom';
import Callback from '../CallbackComponent/CallbackComponent';
import SkeletonComponent from '../Skeleton/Skeleton.component';
import MainContentWrapperComponent from '../MainContentWrapper/MainContentWrapper.component';
import ThemeConfigPage from '../Theme/ThemeConfigPage/ThemePage.component';
import AboutPageComponent from '../AboutComponent/AboutPage.component';
import { useSelector } from 'react-redux';
import { StateModel } from '../../redux/models/state.model';
import { useAppDispatch } from '../../redux/store';
import { fetchUsername, refreshAuthToken } from '../../redux/reducers/authInfo-reducer';
import ErrorPageComponent from '../ErrorPageComponent/ErrorPage.component';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path={'/'} element={<Outlet />}>
      <Route path={'app/module'} element={<SkeletonComponent />}>
        <Route path={':moduleKey/:configPath/:id/:type'} element={<MainContentWrapperComponent />}>
          <Route path={':configPath/:id/:type'} element={<MainContentWrapperComponent />} />
        </Route>
        <Route
          path={':moduleKey/PAGE/:configPath/:id/:type'}
          element={<MainContentWrapperComponent />}
        />
        <Route path="theme" element={<ThemeConfigPage />} />
        <Route path="about" element={<AboutPageComponent />} />
        <Route path={'*'} element={<Navigate to={'/app/module/'} replace={true} />} />
      </Route>
      <Route path={'/'} element={<Navigate to={'/app/module/'} replace={true} />} />
      <Route path="/callback" element={<Callback />} />
      <Route path="/error" element={<ErrorPageComponent />} />
      <Route path={'*'} element={<Navigate to={'/app/module/'} replace={true} />} />
    </Route>,
  ),
);

const RouterComponent = () => {
  const dispatch = useAppDispatch();

  const expiresIn: number | null =
    useSelector((state: StateModel) => state.authInfo.expires_in) ??
    Number(sessionStorage.getItem('expires_in'));

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    if (token) {
      dispatch(fetchUsername(token));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (expiresIn === null) return;

    const refreshInterval = setInterval(() => {
      const refreshToken = sessionStorage.getItem('refresh_token');
      if (!refreshToken) return;
      dispatch(refreshAuthToken(refreshToken)).catch((error) => console.error(error));
    }, expiresIn * 0.75);

    return () => clearInterval(refreshInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expiresIn]);

  return <RouterProvider router={router} />;
};

export default RouterComponent;
